import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../API/axiosInstance'; 

const UserAjout = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [secteur, setSecteur] = useState('');
  const [secteurs, setSecteurs] = useState([]);
  const [entreprise, setEntreprise] = useState('');
  const [entreprises, setEntreprises] = useState([]);
  const [filteredEntreprises, setFilteredEntreprises] = useState([]);
  const [site, setSite] = useState('');
  const [sites, setSites] = useState([]);
  const [manager, setManager] = useState('');
  const [managers, setManagers] = useState([]);
  const [message, setMessage] = useState('');

  // Récupérer les rôles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get('/users/get-roles');
        // const response = await axios.get('http://localhost:5000/api/users/get-roles');
        setRoles(response.data.roles);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles :", error);
      }
    };
    fetchRoles();
  }, []);

  // Récupérer les secteurs
  useEffect(() => {
    const fetchSecteurs = async () => {
      try {
        const response = await axiosInstance.get('/secteurs/get-secteurs');
        //const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
        setSecteurs(response.data.secteurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des secteurs :", error);
      }
    };
    fetchSecteurs();
  }, []);

  // Récupérer les entreprises
  useEffect(() => {
    const fetchEntreprises = async () => {
      try {
        const response = await axiosInstance.get('/entreprises/get-entreprises');
        //const response = await axios.get('http://localhost:5000/api/entreprises/get-entreprises');
        setEntreprises(response.data.entreprises);
      } catch (error) {
        console.error("Erreur lors de la récupération des entreprises :", error);
      }
    };
    fetchEntreprises();
  }, []);

  // Filtrer les entreprises en fonction du secteur sélectionné
  useEffect(() => {
    if (secteur) {
      //setFilteredEntreprises(entreprises.filter((ent) => ent.secteur_id === parseInt(secteur)));
      setFilteredEntreprises(entreprises.filter((ent) => String(ent.secteur_id) === secteur));

      setEntreprise('');
      setSite('');
      setManager('');
      setSites([]);
      setManagers([]);
    } else {
      setFilteredEntreprises([]);
    }
  }, [secteur, entreprises]);

  // Récupérer les sites en fonction de l'entreprise sélectionnée
  useEffect(() => {
    const fetchSites = async () => {
      if (entreprise) {
        try {
          const response = await axiosInstance.get(`/entreprises/get-sites/${entreprise}`);
          //const response = await axios.get(`http://localhost:5000/api/entreprises/get-sites/${entreprise}`);
          setSites(response.data.sites);
        } catch (error) {
          console.error("Erreur lors de la récupération des sites :", error);
          setSites([]);
        }
      } else {
        setSites([]);
      }
    };
    fetchSites();
  }, [entreprise]);

  // Récupérer les managers en fonction du site sélectionné
  useEffect(() => {
    const fetchManagers = async () => {
      if (site) {
        try {
          const response = await axiosInstance.get(`/users/get-managers/${site}`);
          // const response = await axios.get(`http://localhost:5000/api/users/get-managers/${site}`);
          setManagers(response.data.managers);
        } catch (error) {
          console.error("Erreur lors de la récupération des managers :", error);
          setManagers([]);
        }
      } else {
        setManagers([]);
      }
    };
    fetchManagers();
  }, [site]);

  // Affichage de message temporaire en cas de succès ou d'erreur
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`/users/add-user`, {
      // await axios.post('http://localhost:5000/api/users/add-user', {
        nom,
        prenom,
        email,
        password,
        role,
        site: role === 'admin' ? null : site,
        manager: role === 'vendor' ? manager : null
      });
      setMessage('Utilisateur ajouté avec succès!');
      setNom('');
      setPrenom('');
      setEmail('');
      setPassword('');
      setRole('');
      setSecteur('');
      setEntreprise('');
      setSite('');
      setManager('');
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
      setMessage("Erreur lors de l'ajout de l'utilisateur.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4 col-md-8">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Ajouter un Utilisateur</h6>
        </div>
        <div className="card-body">
          {message && (
            <div className={`alert ${message.includes('succès') ? 'alert-success' : 'alert-danger'}`}>
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-row d-flex justify-content-between mb-3">
              <div className="form-group col-md-6">
                <label htmlFor="nom">Nom</label>
                <input
                  type="text"
                  className="form-control"
                  id="nom"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="prenom">Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  id="prenom"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password">Mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="role">Rôle</label>
              <select
                className="form-control"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option value="">Sélectionner un rôle</option>
                {roles.map((roleOption, index) => (
                  <option key={`${roleOption}-${index}`} value={roleOption}>
                    {roleOption}
                  </option>
                ))}
              </select>
            </div>

            {/* Sélection du secteur */}
            <div className="form-group mb-3">
              <label htmlFor="secteur">Secteur</label>
              <select
                className="form-control"
                id="secteur"
                value={secteur}
                onChange={(e) => setSecteur(e.target.value)}
                required
              >
                <option value="">Sélectionner un secteur</option>
                {secteurs.map((secteurOption, index) => (
                  <option key={`${secteurOption.id}-${index}`} value={secteurOption.id}>
                    {secteurOption.nom}
                  </option>
                ))}
              </select>
            </div>

            {/* Sélection d'entreprise */}
            <div className="form-group mb-3">
              <label htmlFor="entreprise">Entreprise</label>
              <select
                className="form-control"
                id="entreprise"
                value={entreprise}
                onChange={(e) => setEntreprise(e.target.value)}
                required
              >
                <option value="">Sélectionner une entreprise</option>
                {filteredEntreprises.map((entrepriseOption, index) => (
                  <option key={`${entrepriseOption.id}-${index}`} value={entrepriseOption.id}>
                    {entrepriseOption.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Sélection de site si le rôle est "vendor" ou "manager" */}
            {(role === 'vendor' || role === 'manager') && (
              <div className="form-group mb-3">
                <label htmlFor="site">Site</label>
                <select
                  className="form-control"
                  id="site"
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                  required
                >
                  <option value="">Sélectionner un site</option>
                  {sites.map((siteOption, index) => (
                    <option key={`${siteOption.id}-${index}`} value={siteOption.id}>
                      {siteOption.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Sélection de manager si le rôle est "vendor" */}
            {role === 'vendor' && (
              <div className="form-group mb-3">
                <label htmlFor="manager">Manager</label>
                <select
                  className="form-control"
                  id="manager"
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                  required
                >
                  <option value="">Sélectionner un manager</option>
                  {managers.map((managerOption, index) => (
                    <option key={`${managerOption.id}-${index}`} value={managerOption.id}>
                      {managerOption.first_name} {managerOption.last_name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <button type="submit" className="btn btn-primary mt-3">Ajouter Utilisateur</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserAjout;
