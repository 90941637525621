import React, { useState, useEffect } from 'react';
import styles from './ParcoursClient.module.css';
import { useTranslation } from 'react-i18next';

const DateAchat = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(new Date().getDate());

  useEffect(() => {
    // Mettre à jour formData sans provoquer de défilement
    setFormData((prevData) => ({
      ...prevData,
      dateAchat: { year: selectedYear, month: selectedMonth, day: selectedDay },
    }));
  }, [selectedYear, selectedMonth, selectedDay, setFormData]);

  const handleYearChange = (e) => setSelectedYear(Number(e.target.value));
  const handleMonthChange = (e) => setSelectedMonth(Number(e.target.value));
  const handleDayChange = (e) => setSelectedDay(Number(e.target.value));

  const handleNextStep = () => {
    if (selectedYear && selectedMonth && selectedDay) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const isFormComplete = selectedYear && selectedMonth && selectedDay;

  // Liste des mois traduits
  const months = [
    t('months.january'),
    t('months.february'),
    t('months.march'),
    t('months.april'),
    t('months.may'),
    t('months.june'),
    t('months.july'),
    t('months.august'),
    t('months.september'),
    t('months.october'),
    t('months.november'),
    t('months.december'),
  ];

  return (
    <div className={`${styles.container} ${styles.my4}`}>
      <h2 className={`${styles.titleHeading} text-center mb-4`}>
        {t('ParcourClient.questions3.date_purchase')}
      </h2>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label>{t('ParcourClient.option_date_purchase.year')}</label>
        <select
          value={selectedYear}
          onChange={handleYearChange}
          className={`${styles.formControl} ${styles.selectStyle}`}
        >
          {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label>{t('ParcourClient.option_date_purchase.month')}</label>
        <select
          value={selectedMonth}
          onChange={handleMonthChange}
          className={`${styles.formControl} ${styles.selectStyle}`}
        >
          {months.map((month, index) => (
            <option key={index} value={index + 1}>
              {month}
            </option>
          ))}
        </select>
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label>{t('ParcourClient.option_date_purchase.day')}</label>
        <select
          value={selectedDay}
          onChange={handleDayChange}
          className={`${styles.formControl} ${styles.selectStyle}`}
        >
          {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </div>

      {isFormComplete && (
        <div className="text-center mt-4">
          <button className={styles.nextStepBtn} onClick={handleNextStep}>
            {t('Button.continue')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DateAchat;
