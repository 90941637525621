import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationPL from './locales/pl.json';
import translationFR from './locales/fr.json';

// Récupérer la langue présélectionnée ou définir une langue par défaut
const defaultLanguage = localStorage.getItem('selectedLanguage') || 'fr';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      pl: { translation: translationPL },
      fr: { translation: translationFR },
    },
    lng: defaultLanguage, // langue par défaut provenant de localStorage
    fallbackLng: "pl", // langue de secours
    interpolation: {
      escapeValue: false, // React s'en occupe
    },
  });

export default i18n;
