import React, { useState, useEffect } from 'react';
import styles from './ParcoursClient.module.css';
import { useTranslation } from 'react-i18next';

const InfoVelo = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const [marque, setMarque] = useState(formData.marque || '');
  const [modele, setModele] = useState(formData.modele || '');
  const [prixAchat, setPrixAchat] = useState(formData.prixAchat || '');

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      marque,
      modele,
      prixAchat,
    }));
  }, [marque, modele, prixAchat, setFormData]);

  const handleContinue = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const isFormComplete = marque && modele && prixAchat;
  
  return (
    <div className={`${styles.container} ${styles.my4}`}>
      <h2 className={`${styles.titleHeading} text-center mb-4`}>
        {t('ParcourClient.questions5.brand_bike')}
      </h2>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="marque">{t('ParcourClient.option_brand_bike.brand')}</label>
        <input
          type="text"
          id="marque"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={marque}
          onChange={(e) => setMarque(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="modele">{t('ParcourClient.option_brand_bike.Model')}</label>
        <input
          type="text"
          id="modele"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={modele}
          onChange={(e) => setModele(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="prixAchat">{t('ParcourClient.option_brand_bike.price')}</label>
        <input
          type="number"
          id="prixAchat"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={prixAchat}
          onChange={(e) => setPrixAchat(e.target.value)}
        />
      </div>

      {isFormComplete && (
        <div className="text-center mt-4">
          <button className={styles.nextStepBtn} onClick={handleContinue}>
            {t('Button.continue')}
          </button>
        </div>
      )}
    </div>
  );
};

export default InfoVelo;
