// src/components/SuperAdmin/Gestion/Entreprise/AssuranceModal.js
import React, { useState, useEffect, useRef } from 'react';
import styles from './Entreprise.module.css';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssuranceModal = ({ showModal, closeModal, selectedSite, updateLinkedAssurance }) => {
  const [assurances, setAssurances] = useState([]);
  const [activeAssurances, setActiveAssurances] = useState({});
  const modalRef = useRef(null);

  useEffect(() => {
    if (selectedSite) {
      const fetchAssurances = async () => {
        try {
          const allAssurancesResponse = await axiosInstance.get('/assurances');
          // const allAssurancesResponse = await axios.get(`http://localhost:5000/api/assurances`);
          const allAssurances = allAssurancesResponse.data;
          const linkedAssurancesResponse = await axiosInstance.get(`/sites/${selectedSite}/linked-assurances`);
          // const linkedAssurancesResponse = await axios.get(`http://localhost:5000/api/sites/${selectedSite}/linked-assurances`);
          const linkedAssurancesIds = linkedAssurancesResponse.data.map(a => a.id);

          const initialActiveState = allAssurances.reduce((acc, assurance) => {
            acc[assurance.id] = linkedAssurancesIds.includes(assurance.id);
            return acc;
          }, {});

          setAssurances(allAssurances);
          setActiveAssurances(initialActiveState);
        } catch (error) {
          console.error('Erreur lors de la récupération des assurances:', error);
        }
      };
      fetchAssurances();
    }
  }, [selectedSite]);

  const toggleAssurance = async (assuranceId) => {
    const isActive = !activeAssurances[assuranceId];
    setActiveAssurances((prev) => ({ ...prev, [assuranceId]: isActive }));

    try {
      await axiosInstance.post('/assurances/toggle', {
      // await axios.post(`http://localhost:5000/api/assurances/toggle`, {
        siteId: selectedSite,
        assuranceId,
        active: isActive,
      });

      const updatedAssurances = assurances.map((assurance) =>
        assurance.id === assuranceId ? { ...assurance, active: isActive } : assurance
      );

      updateLinkedAssurance(selectedSite, updatedAssurances.filter(a => activeAssurances[a.id]));
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'état de l\'assurance:', error);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent} ref={modalRef}>
        <div className={styles.modalHeader}>
          <h5 className={styles.modalTitle}>Assurances du site</h5>
          <button type="button" className={styles.closeButton} onClick={closeModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className={styles.modalBody}>
          {assurances.length > 0 ? (
            <ul className="list-group list-group-flush">
              {assurances.map((assurance) => (
                <li key={assurance.id} className={`list-group-item ${styles.assuranceItem}`}>
                  <span>{assurance.nom}</span>
                  <div className={styles.toggleSwitch}>
                    <input
                      type="checkbox"
                      id={`toggle-${assurance.id}`}
                      checked={activeAssurances[assurance.id] || false}
                      onChange={() => toggleAssurance(assurance.id)}
                    />
                    <label htmlFor={`toggle-${assurance.id}`} className={styles.switchLabel}>
                      <span className={styles.switchButton}></span>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucune assurance trouvée.</p>
          )}
        </div>
        <div className={styles.modalFooter}>
          <button type="button" className="btn btn-secondary" onClick={closeModal}>
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssuranceModal;
