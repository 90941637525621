import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './VendorLogin.module.css';
import { useTranslation } from 'react-i18next';
import francais from '../../../assets/images/drapeaux/francais.png';
import anglais from '../../../assets/images/drapeaux/anglais.png';
import polonais from '../../../assets/images/drapeaux/polonais.png';
import axiosInstance from '../../../API/axiosInstance'; 

const VendorLogin = ({ setIsAuthenticated }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/vendor-login', { email, password });
      // const response = await axios.post('/api/vendor-login', { email, password });
      const { token, vendorId } = response.data;

      if (token && vendorId) {
        localStorage.setItem('token', token);
        localStorage.setItem('vendorId', vendorId);

        setIsAuthenticated(true);
        navigate('/vendor-panel');
      } else {
        setError(t("Login.organism"));
      }
    } catch (err) {
      setError(t('Login.inputpassword'));
    }
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  };

  return (
    <div className={`${styles.container} d-flex align-items-center justify-content-center vh-100`}>
      <div className={`${styles.card} card p-4 shadow`}>
        <h2 className={`${styles.title} text-center mb-4`}>{t('Login.title')}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">{t('Login.email')}</label>
            <input
              type="email"
              id="email"
              className={`${styles.input} form-control`}
              placeholder={t('Login.inputemail')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">{t('Login.password')}</label>
            <input
              type="password"
              id="password"
              className={`${styles.input} form-control`}
              placeholder={t('Login.inputpassword')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className={`${styles.button} btn w-100`}>
            {t('Login.connection')}
          </button>
          <div className={`${styles.links} d-flex justify-content-between mt-3`}>
            <a href="/forgot-password" className={styles.link}>{t('Login.forgottenpassword')}</a>
            <span 
              className={styles.link} 
              onClick={() => setIsPopupOpen(true)} 
              style={{ cursor: 'pointer' }}
            >
              {t('Login.noaccount')}
            </span>
          </div>
        </form>
        
        <div className={`${styles.languageSelector} mt-4 d-flex justify-content-center`}>
          <img src={francais} alt="Français" className={`${styles.flagIcon}`} onClick={() => changeLanguage('fr')} />
          <img src={anglais} alt="English" className={`${styles.flagIcon}`} onClick={() => changeLanguage('en')} />
          <img src={polonais} alt="Polski" className={`${styles.flagIcon}`} onClick={() => changeLanguage('pl')} />
        </div>
      </div>

      {isPopupOpen && (
        <div className={styles.popupOverlay} onClick={() => setIsPopupOpen(false)}>
          <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
            <p>{t('Login.organism')}</p>
            <button onClick={() => setIsPopupOpen(false)} className={styles.closeButton}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorLogin;
