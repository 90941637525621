import React, { useEffect, useState } from 'react';
import VendorNavbar from '../VendorNavbar';
import { useNavigate, useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import styles from './Assurances.module.css';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../API/axiosInstance'; 

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
    <path d="M13.485 1.757a.75.75 0 0 1 1.06 1.06l-8.485 8.485a.75.75 0 0 1-1.06 0L1.56 7.485a.75.75 0 0 1 1.06-1.06L6 9.795l7.485-7.485z"/>
  </svg>
);

const CrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
  </svg>
);

const QuestionIcon = () => <i className="bi bi-question-circle" />;

const Assurances = () => {
  const { t } = useTranslation();
  const { siteId } = useParams();
  const [assurances, setAssurances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssurances = async () => {
      try {
        const vendorId = localStorage.getItem('vendorId');
        if (!vendorId) throw new Error('Vendor ID manquant');

        const response = await axiosInstance.get(`/sites/${vendorId}/assurances`);
        // const response = await axios.get(`http://localhost:5000/api/sites/${vendorId}/assurances`);

        // Utiliser un Set pour s'assurer que chaque assurance est unique
        const uniqueAssurances = Array.from(new Set(response.data.map(ass => ass.id)))
          .map(id => response.data.find(ass => ass.id === id));

        setAssurances(uniqueAssurances);
      } catch (error) {
        setError("Erreur lors de la récupération des assurances.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssurances();
  }, [siteId]);

  const handlePdfClick = (base64Data) => {
    if (!base64Data) {
      alert("Le document PDF est manquant.");
      return;
    }

    const binaryString = window.atob(base64Data);
    const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
    const blob = new Blob([bytes], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  };

  const guarantees = [
    { label: t("AssurancePage.steal"), key: "vol" },
    { label: t("AssurancePage.attemptsteal"), key: "tentative_vol" },
    { label: t("AssurancePage.break"), key: "dommages_materiels" },
    { label: t("AssurancePage.naturaldisasters"), key: "catastrophes_naturelles" },
    { label: t("AssurancePage.technologikdisasters"), key: "catastrophes_technologiques" }
  ];

  return (
    <div>
      <VendorNavbar showBackButton={true} stepBack={() => navigate('/vendor-panel')} />
      <div className={styles.contentContainer}>
        <h1 className={styles.assurancesTitle}>
          {t("AssurancePage.assurance")}
          <span className={styles.assuranceCount}>{assurances.length}</span>
        </h1>
        {loading ? (
          <div className="text-center">Chargement...</div>
        ) : error ? (
          <div className="text-center text-danger">{error}</div>
        ) : (
          <div className="row">
            {assurances.map((assurance, index) => (
              <div className="col-md-6 mb-4" key={`${assurance.id}-${index}`}>
                <div className={`card ${styles.assuranceCard}`}>
                  <div className={`card-header ${styles.cardHeader}`}>
                    <h5>{assurance.nom ? assurance.nom.toUpperCase() : "Nom inconnu"}</h5>
                  </div>
                  <div className={`card-body ${styles.assuranceContent}`}>
                    <div className={styles.assuranceDetails}>
                      <h6 className={styles.assurancetxt}><strong>{t("AssurancePage.value")} : </strong><span className={styles.assuranceValue}>{assurance.prix} € / {t("ParcourClient.option_date_purchase.month")}</span></h6>
                      <p className="text-muted"><strong>{t("AssurancePage.description")} </strong> {assurance.description || "Description non disponible"}</p>
                    </div>

                    <table className={styles.guaranteesTable}>
                      <thead>
                        <tr>
                          <th>{t("AssurancePage.guarantees")}</th>
                          <th>{t("AssurancePage.status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guarantees.map((guarantee) => {
                          const franchise = assurance[`${guarantee.key}_franchise`];
                          const minFranchise = assurance[`min_fr_${guarantee.key}`];
                          return (
                            <tr key={`${assurance.id}-${guarantee.key}`}>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {franchise !== undefined && <div>{t("AssurancePage.franchise")} : {franchise}%</div>}
                                      {minFranchise !== undefined && <div>{t("AssurancePage.minimum")} : {minFranchise} €</div>}
                                    </Tooltip>
                                  }
                                >
                                  <span className={styles.infoIcon}><QuestionIcon /></span>
                                </OverlayTrigger>
                                <span className={styles.guaranteeLabel}>{guarantee.label}</span>
                              </td>
                              <td className="text-center">
                                {assurance[guarantee.key] ? (
                                  <CheckIcon className={styles.checkIcon} />
                                ) : (
                                  <CrossIcon className={styles.crossIcon} />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="mt-3">
                      {assurance.pdf_ipid && (
                        <a href={handlePdfClick(assurance.pdf_ipid)} target="_blank" rel="noopener noreferrer" className={styles.pdfLink}>
                          📄 {t("AssurancePage.ipid")}
                        </a>
                      )}
                      {assurance.pdf_notice && (
                        <a href={handlePdfClick(assurance.pdf_notice)} target="_blank" rel="noopener noreferrer" className={styles.pdfLink}>
                          📑 {t("AssurancePage.notice")}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Assurances;
