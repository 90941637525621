// src/components/SuperAdmin/Gestion/Assurance/AssuranceView.js

import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import AssuranceForm from './AssuranceForm';
import AssuranceSelect from './AssuranceSelect';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssuranceView = () => {
  const [assurances, setAssurances] = useState([]);
  const [filteredAssurances, setFilteredAssurances] = useState([]);
  const [selectedAssurance, setSelectedAssurance] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedAssureur, setSelectedAssureur] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedSecteur, setSelectedSecteur] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');
  
  const [categories, setCategories] = useState([]);
  const [assureurs, setAssureurs] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secteurs, setSecteurs] = useState([]);

  // Fonction pour récupérer les filtres
  const fetchFilters = useCallback(async () => {
    try {
      const [categoriesRes, assureursRes, statusesRes, secteursRes] = await Promise.all([
        axiosInstance.get('/categories/get-categories'),
        axiosInstance.get('/assureurs/get-assureurs'),
        axiosInstance.get('/status/get-statuses'),
        axiosInstance.get('/secteurs/get-secteurs')       
        // axios.get('http://localhost:5000/api/categories/get-categories'),
        // axios.get('http://localhost:5000/api/assureurs/get-assureurs'),
        // axios.get('http://localhost:5000/api/status/get-statuses'),
        // axios.get('http://localhost:5000/api/secteurs/get-secteurs')
      ]);
      setCategories(categoriesRes.data.categories);
      setAssureurs(assureursRes.data.assureurs);
      setStatuses(statusesRes.data.statuses);
      setSecteurs(secteursRes.data.secteurs);
    } catch (error) {
      console.error("Erreur lors de la récupération des filtres:", error);
    }
  }, []);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  // Fonction pour récupérer les assurances avec les filtres sélectionnés
  const fetchAssurances = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/assurances', {
      // const response = await axios.get('http://localhost:5000/api/assurances', {
        params: {
          category: selectedCategory,
          assureur: selectedAssureur,
          status: selectedStatus,
          secteur: selectedSecteur,
          searchTerm: searchTerm
        }
      });
      setAssurances(response.data);
      setFilteredAssurances(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des assurances:', error);
    }
  }, [selectedCategory, selectedAssureur, selectedStatus, selectedSecteur, searchTerm]);

  useEffect(() => {
    fetchAssurances();
  }, [fetchAssurances]);

  return (
    <div className="container-fluid px-2">
      <AssuranceSelect
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedAssureur={selectedAssureur}
        setSelectedAssureur={setSelectedAssureur}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedSecteur={selectedSecteur}
        setSelectedSecteur={setSelectedSecteur}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        categories={categories}
        assureurs={assureurs}
        statuses={statuses}
        secteurs={secteurs}
      />
      <div className="row">
        <div className="col-md-4">
          <div className="card shadow mb-4" style={{ maxHeight: '450px', overflowY: 'auto' }}>
            <ul className="list-group list-group-flush">
              {filteredAssurances.length > 0 ? (
                filteredAssurances.map((assurance) => (
                  <li
                    key={assurance.id}
                    className={`list-group-item d-flex justify-content-between align-items-center ${assurance.id === selectedAssurance?.id ? 'active' : ''}`}
                    onClick={() => setSelectedAssurance(assurance)}
                  >
                    <div>
                      <strong>{assurance.nom}</strong>
                      <p className="mb-0"><small>{assurance.assureur_nom || "Assureur inconnu"}</small></p>
                    </div>
                    <FontAwesomeIcon icon={faEye} />
                  </li>
                ))
              ) : (
                <li className="list-group-item">Aucune assurance trouvée</li>
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          <AssuranceForm
            selectedAssurance={selectedAssurance}
            setSelectedAssurance={setSelectedAssurance}
            setMessage={setMessage}
            updateAssuranceList={fetchAssurances}
            categories={categories}
            assureurs={assureurs}
            statuses={statuses}
            secteurs={secteurs}
          />
          {message && <div className="alert alert-info mt-3">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AssuranceView);
