import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './VendeurAccueil.module.css';
import { FaCashRegister, FaHistory, FaShieldAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


const VendeurAccueil = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add(styles.accueilPage);
    return () => {
      document.body.classList.remove(styles.accueilPage);
    };
  }, []);

  return (
    <div className={`container-fluid ${styles.accueilContainer} d-flex align-items-center justify-content-center`}>
      <div className={styles.overlay}>
        <h1 className={styles.welcomeText}>{t('PageAccueil.bonjour')}</h1> 
        <div className={styles.buttonGroup}>
          <button
            className={`btn ${styles.largeBtn} mb-3`}
            onClick={() => navigate('/vendor-panel/parcours-client')}
          >
            <FaCashRegister className={styles.icon} /> {t('PageAccueil.nouvelle_vente')}
          </button>
          <button
            className={`btn ${styles.mediumBtn} mb-3`}
            onClick={() => navigate('/vendor-panel/historique')}
          >
            <FaHistory className={styles.icon} /> {t('PageAccueil.historique')}
          </button>
          <button
            className={`btn ${styles.mediumBtn}`}
            onClick={() => navigate('/vendor-panel/assurances')}
          >
            <FaShieldAlt className={styles.icon} /> {t('PageAccueil.assurances')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VendeurAccueil;
