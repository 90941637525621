import React, { useState, useEffect } from 'react';
import styles from './ParcoursClient.module.css';
import { useTranslation } from 'react-i18next';

const ClientInfo = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const [nom, setNom] = useState(formData.nom || '');
  const [prenom, setPrenom] = useState(formData.prenom || '');
  const [email, setEmail] = useState(formData.email || '');
  const [telephone, setTelephone] = useState(formData.telephone || '');
  const [adresse, setAdresse] = useState(formData.adresse || '');
  const [ville, setVille] = useState(formData.ville || '');
  const [codePostal, setCodePostal] = useState(formData.codePostal || '');

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      nom,
      prenom,
      email,
      telephone,
      adresse,
      ville,
      codePostal,
    }));
  }, [nom, prenom, email, telephone, adresse, ville, codePostal, setFormData]);

  const handleContinue = () => {
    if (nom && prenom && email && telephone && adresse && ville && codePostal) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <div className={`${styles.container} ${styles.my4}`}>
      <h2 className={`${styles.titleHeading} text-center mb-4`}>{t('ParcourClient.questions6.info_custommer')}</h2>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="nom">{t('ParcourClient.option_info_custommer.name')}</label>
        <input
          type="text"
          id="nom"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={nom}
          onChange={(e) => setNom(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="prenom">{t('ParcourClient.option_info_custommer.surname')}</label>
        <input
          type="text"
          id="prenom"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="email">{t('ParcourClient.option_info_custommer.email')}</label>
        <input
          type="email"
          id="email"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="telephone">{t('ParcourClient.option_info_custommer.smartphone')}</label>
        <input
          type="tel"
          id="telephone"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="adresse">{t('ParcourClient.option_info_custommer.adress')}</label>
        <input
          type="text"
          id="adresse"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={adresse}
          onChange={(e) => setAdresse(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="ville">{t('ParcourClient.option_info_custommer.city')}</label>
        <input
          type="text"
          id="ville"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={ville}
          onChange={(e) => setVille(e.target.value)}
        />
      </div>

      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="codePostal">{t('ParcourClient.option_info_custommer.postalcode')}</label>
        <input
          type="text"
          id="codePostal"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={codePostal}
          onChange={(e) => setCodePostal(e.target.value)}
        />
      </div>

      {nom && prenom && email && telephone && adresse && ville && codePostal && (
        <div className="text-center mt-4">
          <button className={styles.nextStepBtn} onClick={handleContinue}>
            {t('Button.continue')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
