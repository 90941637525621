// src/components/SuperAdmin/Gestion/Entreprise/EntrepriseSelect.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../API/axiosInstance'; 

const EntrepriseSelect = ({ selectedType, selectedStatus, setSelectedType, setSelectedStatus, searchTerm, setSearchTerm }) => {
  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchSecteurs = async () => {
      try {
        const response = await axiosInstance.get('/secteurs/get-secteurs');
        // const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
        setTypes(response.data.secteurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des secteurs :", error);
        setMessage("Erreur lors de la récupération des secteurs.");
      }
    };

    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/status/get-statuses');
        // const response = await axios.get('http://localhost:5000/api/status/get-statuses');
        setStatuses(response.data.statuses);
      } catch (error) {
        console.error("Erreur lors de la récupération des statuts :", error);
        setMessage("Erreur lors de la récupération des statuts.");
      }
    };

    fetchSecteurs();
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="card shadow mb-4 entreprise-select-card w-100">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Filtrer les entreprises</h6>
      </div>
      <div className="card-body">
        {message && (
          <div className={`alert ${message.includes('Erreur') ? 'alert-danger' : 'alert-success'}`}>
            {message}
          </div>
        )}
        <form>
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="secteur-select">Secteur</label>
              <select
                id="secteur-select"
                className="form-control"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">Tous les secteurs</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>{type.nom}</option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="status-select">Statut</label>
              <select
                id="status-select"
                className="form-control"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Tous les statuts</option>
                {statuses.map(status => (
                  <option key={status.id} value={status.id}>{status.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="search-input">Rechercher</label>
              <input
                id="search-input"
                type="text"
                className="form-control"
                placeholder="Rechercher une entreprise"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EntrepriseSelect;
