import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import VendorNavbar from '../VendorNavbar';
import BikeSelection from './BikeSelection';
import DeplacementType from './DeplacementType';
import ProprieteVelo from './ProprieteVelo';
import DateAchat from './DateAchat';
import InfoVelo from './InfoVelo';
import ClientInfo from './ClientInfo';
import Recapitulatif from './Recapitulatif';
import LoadingPayement from '../Load/LoadingPayment';
import SelectionAssurance from './SelectionAssurance';
import styles from './ParcoursClient.module.css';

const ParcoursClient = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    bikeType: '',
    deplacementType: '',
    siteId: '12345',
  });
  const [vendorId, setVendorId] = useState(null);
  const navigate = useNavigate();
  const contentRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setVendorId(decodedToken.id);
      } catch (error) {
        console.error('Erreur lors du décodage du token', error);
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleFinish = (clientId, transactionId) => {
    setFormData({ ...formData, clientId, transactionId });
    setCurrentStep(currentStep + 1);
  };

  const steps = [
    <BikeSelection formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <DeplacementType formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <DateAchat formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <ProprieteVelo formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <InfoVelo formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <ClientInfo formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <SelectionAssurance formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <Recapitulatif formData={formData} vendorId={vendorId} handleFinish={handleFinish} />,
    formData.transactionId ? <LoadingPayement transactionId={formData.transactionId} /> : null,
  ];

  const totalStepsForProgress = steps.length - 1;

  const handleStepBack = () => {
    if (currentStep === 1) {
      navigate('/vendor-panel');
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <VendorNavbar hideSearchBar={true} showBackButton={true} stepBack={handleStepBack} />

      <div className={styles.progressContainer}>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            style={{
              width: `${(Math.min(currentStep, totalStepsForProgress) / totalStepsForProgress) * 100}%`,
            }}
            role="progressbar"
            aria-valuenow={(Math.min(currentStep, totalStepsForProgress) / totalStepsForProgress) * 100}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.contentContainer} ref={contentRef}>
          {steps[currentStep - 1]}
        </div>
      </div>
    </>
  );
};

export default ParcoursClient;
