// src/Ajouts/Secteur/SecteurCategAjout.js
import React, { useState } from 'react';
import axiosInstance from '../../../../API/axiosInstance'; 

const SecteurCategAjout = ({ secteurs, fetchSecteurs, onCategorieAdded }) => {
  const [secteurNom, setSecteurNom] = useState('');
  const [categorieNom, setCategorieNom] = useState('');
  const [secteurId, setSecteurId] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (secteurNom) {
        // Ajout d'un secteur
        const secteurResponse = await axiosInstance.post('/entreprises/secteurs', { nom: secteurNom });
        //const secteurResponse = await axios.post('http://localhost:5000/api/entreprises/secteurs', { nom: secteurNom });
        if (secteurResponse.status === 201) {  // Vérifiez bien le code 201
          setMessage('Secteur ajouté avec succès!');
          setSecteurNom('');
          fetchSecteurs();
        }
      }

      if (categorieNom && secteurId) {
        // Ajout d'une catégorie
        const categorieResponse = await axiosInstance.post('/entreprises/categories', {
        //const categorieResponse = await axios.post('http://localhost:5000/api/entreprises/categories', {
          nom: categorieNom,
          secteur_id: secteurId,
        });
        if (categorieResponse.status === 201) {  // Vérifiez bien le code 201
          setMessage('Catégorie ajoutée avec succès!');
          setCategorieNom('');
          setSecteurId('');
          if (onCategorieAdded) {
            onCategorieAdded(categorieResponse.data);
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout:", error);
      setMessage("Erreur lors de l'ajout.");
    }
  };

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Création de Secteur et Catégorie</h6>
      </div>
      <div className="card-body">
        {message && <div className={`alert ${message.includes('succès') ? 'alert-success' : 'alert-danger'}`}>{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nom du Secteur</label>
            <input
              type="text"
              className="form-control"
              value={secteurNom}
              onChange={(e) => setSecteurNom(e.target.value)}
            />
          </div>
          <hr />
          <div className="form-group">
            <label>Secteur pour la Catégorie</label>
            <select
              className="form-control"
              value={secteurId}
              onChange={(e) => setSecteurId(e.target.value)}
            >
              <option value="">Sélectionnez un secteur</option>
              {secteurs.map((secteur) => (
                <option key={secteur.id} value={secteur.id}>{secteur.nom}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nom de la Catégorie</label>
            <input
              type="text"
              className="form-control"
              value={categorieNom}
              onChange={(e) => setCategorieNom(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">Ajouter</button>
        </form>
      </div>
    </div>
  );
};

export default SecteurCategAjout;
