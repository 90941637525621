// src/components/SuperAdmin/Gestion/Entreprise/EntrepriseView.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import EntrepriseSelect from './EntrepriseSelect';
import EntrepriseForm from './EntrepriseForm';
import axiosInstance from '../../../../API/axiosInstance'; 

const EntrepriseView = () => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');

  // Récupération des entreprises avec leurs statuts
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axiosInstance.get('/entreprises/get-entreprises');
        // const response = await axios.get('http://localhost:5000/api/entreprises/get-entreprises');
        setCompanies(response.data.entreprises);
        setFilteredCompanies(response.data.entreprises);
      } catch (error) {
        console.error("Erreur lors de la récupération des entreprises:", error);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    let filtered = companies;
    if (selectedType) {
      filtered = filtered.filter(company => company.secteur_id === parseInt(selectedType));
    }
    if (selectedStatus) {
      filtered = filtered.filter(company => company.status_id === parseInt(selectedStatus));
    }
    if (searchTerm) {
      filtered = filtered.filter(company =>
        company.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredCompanies(filtered);
  }, [selectedType, selectedStatus, searchTerm, companies]);

  const updateCompanyList = async () => {
    try {
      const response = await axiosInstance.get('/entreprises/get-entreprises');
      // const response = await axios.get('http://localhost:5000/api/entreprises/get-entreprises');
      setCompanies(response.data.entreprises);
      setFilteredCompanies(response.data.entreprises);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des entreprises:", error);
    }
  };

  return (
    <div className="container-fluid px-2">
      <EntrepriseSelect
        selectedType={selectedType}
        selectedStatus={selectedStatus}
        setSelectedType={setSelectedType}
        setSelectedStatus={setSelectedStatus}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <div className="row">
        <div className="col-md-4 pl-1 pr-2">
          <div className="card shadow mb-4" style={{ maxHeight: '450px', overflowY: 'auto' }}>
            <ul className="list-group list-group-flush">
              {filteredCompanies.length > 0 ? (
                filteredCompanies.map(company => (
                  <li
                    key={company.id}
                    className={`list-group-item d-flex justify-content-between align-items-center ${company.id === selectedCompany?.id ? 'active' : ''}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedCompany(company)}
                  >
                    <div>
                      <strong>{company.name}</strong>
                      <p className="mb-0"><small>{company.status}</small></p>
                    </div>
                    <FontAwesomeIcon icon={faEye} />
                  </li>
                ))
              ) : (
                <li className="list-group-item">Aucune entreprise trouvée</li>
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-8 pl-2 pr-1">
          {selectedCompany ? (
            <EntrepriseForm
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              setMessage={setMessage}
              updateCompanyList={updateCompanyList}
            />
          ) : (
            <div className="alert alert-info">Sélectionnez une entreprise pour voir les détails.</div>
          )}
          {message && <div className="alert alert-info mt-3">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default EntrepriseView;
