import React from 'react';
import styles from './ParcoursClient.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const DeplacementType = ({ setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const handleSelection = (deplacementType) => {
    setFormData((prevData) => ({
      ...prevData,
      deplacementType,
    }));
    setCurrentStep(3); 
  };

  return (
    <div>
      <h2 className={`text-center mb-4 ${styles.titleHeading}`}>
        {t('ParcourClient.questions2.type_moving')}
      </h2>
      <div className="d-flex flex-column align-items-center">
        {/* Carte pour les Particuliers */}
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('Particuliers')}
        >
          <div className={styles.cardBody}>
            <FontAwesomeIcon icon={faUser} size="2x" className={`${styles.icon} me-2`} /> {/* Icône noire avec espace */}
            <h5 className={styles.cardTitle}>{t('ParcourClient.option_type_moving.individuals')}</h5>
          </div>
        </div>

        {/* Carte pour les Professionnels */}
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('Professionnels')}
        >
          <div className={styles.cardBody}>
            <FontAwesomeIcon icon={faBriefcase} size="2x" className={`${styles.icon} me-2`} /> {/* Icône noire avec espace */}
            <h5 className={styles.cardTitle}>{t('ParcourClient.option_type_moving.professionals')}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeplacementType;
