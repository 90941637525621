// src/Ajouts/Assureur/AssureurAjout.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SecteurCategAjout from '../Categorie/SecteurCategAjout';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssureurAjout = () => {
  const [nom, setNom] = useState('');
  const [siret, setSiret] = useState('');
  const [orias, setOrias] = useState('');
  const [statusId, setStatusId] = useState('');
  const [secteurId, setSecteurId] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [adresse, setAdresse] = useState({
    rue: '',
    code_postal: '',
    ville: '',
    region: '',
    pays: ''
  });
  const [message, setMessage] = useState('');

  // Fonction pour récupérer les secteurs et les statuts
  const fetchSecteurs = async () => {
    try {
      const response = await axiosInstance.get('/secteurs/get-secteurs');
      //const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
      setSecteurs(response.data.secteurs || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des secteurs :", error);
    }
  };

  const fetchStatuses = async () => {
    try {
      const response = await axiosInstance.get('/status/get-statuses');
      //const response = await axios.get('http://localhost:5000/api/status/get-statuses');
      setStatuses(response.data.statuses || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des statuts :", error);
    }
  };

  useEffect(() => {
    fetchSecteurs();
    fetchStatuses();
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        nom,
        siret,
        orias,
        status_id: statusId,
        secteur_id: secteurId,
        adresse
      };
      await axiosInstance.post('/assureurs/add-assurance', data);
      // await axios.post('http://localhost:5000/api/assureurs/add-assurance', data);
      setMessage('Assureur ajouté avec succès!');
      setNom('');
      setSiret('');
      setOrias('');
      setStatusId('');
      setSecteurId('');
      setAdresse({
        rue: '',
        code_postal: '',
        ville: '',
        region: '',
        pays: ''
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'assureur:", error);
      setMessage("Erreur lors de l'ajout de l'assureur.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Ajouter un Assureur</h6>
            </div>
            <div className="card-body">
              {message && (
                <div className={`alert ${message.includes('succès') ? 'alert-success' : 'alert-danger'}`}>
                  {message}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="nom">Nom de l'Assureur</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="siret">Numéro de SIRET</label>
                  <input
                    type="text"
                    className="form-control"
                    id="siret"
                    value={siret}
                    onChange={(e) => setSiret(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="orias">Numéro ORIAS</label>
                  <input
                    type="text"
                    className="form-control"
                    id="orias"
                    value={orias}
                    onChange={(e) => setOrias(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Secteur</label>
                  <select
                    className="form-control"
                    value={secteurId}
                    onChange={(e) => setSecteurId(e.target.value)}
                    required
                  >
                    <option value="">Sélectionnez un secteur</option>
                    {secteurs.map((secteur) => (
                      <option key={secteur.id} value={secteur.id}>
                        {secteur.nom}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>Statut</label>
                  <select
                    className="form-control"
                    value={statusId}
                    onChange={(e) => setStatusId(e.target.value)}
                    required
                  >
                    <option value="">Sélectionnez un statut</option>
                    {statuses.map((status) => (
                      <option key={status.id} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </select>
                </div>
                <h5>Adresse</h5>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rue"
                    value={adresse.rue}
                    onChange={(e) => setAdresse({ ...adresse, rue: e.target.value })}
                    required
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Code Postal"
                    value={adresse.code_postal}
                    onChange={(e) => setAdresse({ ...adresse, code_postal: e.target.value })}
                    required
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Ville"
                    value={adresse.ville}
                    onChange={(e) => setAdresse({ ...adresse, ville: e.target.value })}
                    required
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Région"
                    value={adresse.region}
                    onChange={(e) => setAdresse({ ...adresse, region: e.target.value })}
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Pays"
                    value={adresse.pays}
                    onChange={(e) => setAdresse({ ...adresse, pays: e.target.value })}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary mt-3">Ajouter Assureur</button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <SecteurCategAjout secteurs={secteurs} fetchSecteurs={fetchSecteurs} />
        </div>
      </div>
    </div>
  );
};

export default AssureurAjout;
