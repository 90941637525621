// src/components/SuperAdmin/Gestion/User/UserSelect.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../API/axiosInstance'; 

const UserSelect = ({
  selectedSecteur, selectedEntreprise, selectedRole,
  setSelectedSecteur, setSelectedEntreprise, setSelectedRole,
  searchTerm, setSearchTerm
}) => {
  const [secteurs, setSecteurs] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchSecteurs = async () => {
      try {
        const response = await axiosInstance.get('/secteurs/get-secteurs');
        // const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
        setSecteurs(response.data.secteurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des secteurs :", error);
        setMessage("Erreur lors de la récupération des secteurs.");
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get('/users/get-roles');
        // const response = await axios.get('http://localhost:5000/api/users/get-roles');
        setRoles(response.data.roles);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles :", error);
        setMessage("Erreur lors de la récupération des rôles.");
      }
    };

    fetchSecteurs();
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchEntreprises = async () => {
      try {
        const response = await axiosInstance.get('/entreprises/get-entreprises');
        // const response = await axios.get('http://localhost:5000/api/entreprises/get-entreprises');
        let entreprisesData = response.data.entreprises;

        // Filtrer les entreprises localement par secteur si nécessaire
        if (selectedSecteur) {
          entreprisesData = entreprisesData.filter(entreprise => entreprise.secteur_id === parseInt(selectedSecteur));
        }

        setEntreprises(entreprisesData);
      } catch (error) {
        console.error("Erreur lors de la récupération des entreprises :", error);
        setMessage("Erreur lors de la récupération des entreprises.");
      }
    };

    fetchEntreprises();
  }, [selectedSecteur]);

  return (
    <div className="card shadow mb-4 w-100">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Filtrer les utilisateurs</h6>
      </div>
      <div className="card-body">
        {message && (
          <div className={`alert ${message.includes('Erreur') ? 'alert-danger' : 'alert-success'}`}>
            {message}
          </div>
        )}
        <form>
          <div className="row mb-3">
            <div className="col-md-3">
              <label>Secteur</label>
              <select
                className="form-control"
                value={selectedSecteur}
                onChange={(e) => setSelectedSecteur(e.target.value)}
              >
                <option value="">Tous les secteurs</option>
                {secteurs.map(secteur => (
                  <option key={secteur.id} value={secteur.id}>{secteur.nom}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <label>Entreprise</label>
              <select
                className="form-control"
                value={selectedEntreprise}
                onChange={(e) => setSelectedEntreprise(e.target.value)}
                disabled={!selectedSecteur}
              >
                <option value="">Toutes les entreprises</option>
                {entreprises.map(entreprise => (
                  <option key={entreprise.id} value={entreprise.id}>{entreprise.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <label>Rôle</label>
              <select
                className="form-control"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Tous les rôles</option>
                {roles.map((role, index) => (
                  <option key={index} value={role}>{role}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <label>Rechercher</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom ou email"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserSelect;
