// src/components/SuperAdmin/Gestion/Assureur/AssureurForm.js
import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssureurForm = ({ selectedAssureur, setSelectedAssureur, updateAssureurList, setMessage }) => {
  const [formData, setFormData] = useState({
    nom: '',
    siret: '',
    orias: '',
    secteur_id: '',
    status_id: '',
    rue: '',
    code_postal: '',
    ville: '',
    region: '',
    pays: ''
  });
  const [secteurs, setSecteurs] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isLocked, setIsLocked] = useState(true);
  const [localMessage, setLocalMessage] = useState('');

  const toggleLock = () => setIsLocked(!isLocked);

  // Récupération des données pour les options de secteur et statut
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const secteurResponse = await axiosInstance.get('/secteurs/get-secteurs');
        // const secteurResponse = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
        setSecteurs(secteurResponse.data.secteurs || []);

        const statusResponse = await axiosInstance.get('/status/get-statuses');
        // const statusResponse = await axios.get('http://localhost:5000/api/status/get-statuses');
        setStatuses(statusResponse.data.statuses || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des options :", error);
      }
    };

    fetchOptions();
  }, []);

  // Pré-remplir le formulaire si un assureur est sélectionné
  useEffect(() => {
    if (selectedAssureur) {
      setFormData({
        nom: selectedAssureur.nom,
        siret: selectedAssureur.siret,
        orias: selectedAssureur.orias,
        secteur_id: selectedAssureur.secteur_id || '',
        status_id: selectedAssureur.status_id || '',
        rue: selectedAssureur.adresse.rue,
        code_postal: selectedAssureur.adresse.code_postal,
        ville: selectedAssureur.adresse.ville,
        region: selectedAssureur.adresse.region,
        pays: selectedAssureur.adresse.pays
      });
    }
  }, [selectedAssureur]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = selectedAssureur
        ? `/assureurs/update-assurance/${selectedAssureur.id}`
        : '/assureurs';
      const method = selectedAssureur ? 'put' : 'post';
      
      await axiosInstance[method](url, formData); // Utilise axiosInstance ici
      setMessage("Assureur sauvegardé avec succès !");
      updateAssureurList();
      setIsLocked(true); // Verrouille le formulaire après la sauvegarde
      setLocalMessage("Assureur mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assureur :", error);
      setLocalMessage("Erreur lors de la mise à jour de l'assureur.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">{selectedAssureur ? 'Modifier un Assureur' : 'Ajouter un Assureur'}</h6>
          <button className="btn btn-secondary" onClick={toggleLock}>
            <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />
          </button>
        </div>
        <div className="card-body">
          {localMessage && <div className="alert alert-success mb-3">{localMessage}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-row mb-3">
              <div className="form-group col-md-6">
                <label>Nom</label>
                <input
                  type="text"
                  className="form-control"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                  required
                  disabled={isLocked}
                />
              </div>
              <div className="form-group col-md-6">
                <label>SIRET</label>
                <input
                  type="text"
                  className="form-control"
                  name="siret"
                  value={formData.siret}
                  onChange={handleChange}
                  required
                  disabled={isLocked}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label>ORIAS</label>
              <input
                type="text"
                className="form-control"
                name="orias"
                value={formData.orias}
                onChange={handleChange}
                required
                disabled={isLocked}
              />
            </div>

            <div className="form-group mb-3">
              <label>Secteur</label>
              <select
                className="form-control"
                name="secteur_id"
                value={formData.secteur_id}
                onChange={handleChange}
                disabled={isLocked}
                required
              >
                <option value="">Sélectionnez un secteur</option>
                {secteurs.map(secteur => (
                  <option key={secteur.id} value={secteur.id}>{secteur.nom}</option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Statut</label>
              <select
                className="form-control"
                name="status_id"
                value={formData.status_id}
                onChange={handleChange}
                disabled={isLocked}
                required
              >
                <option value="">Sélectionnez un statut</option>
                {statuses.map(status => (
                  <option key={status.id} value={status.id}>{status.name}</option>
                ))}
              </select>
            </div>

            <h5>Adresse</h5>
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Rue"
                name="rue"
                value={formData.rue}
                onChange={handleChange}
                required
                disabled={isLocked}
              />
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Code Postal"
                name="code_postal"
                value={formData.code_postal}
                onChange={handleChange}
                required
                disabled={isLocked}
              />
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Ville"
                name="ville"
                value={formData.ville}
                onChange={handleChange}
                required
                disabled={isLocked}
              />
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Région"
                name="region"
                value={formData.region}
                onChange={handleChange}
                disabled={isLocked}
              />
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Pays"
                name="pays"
                value={formData.pays}
                onChange={handleChange}
                required
                disabled={isLocked}
              />
            </div>

            <button type="submit" className="btn btn-primary mt-3" disabled={isLocked}>
              <FontAwesomeIcon icon={faSave} /> Sauvegarder
            </button>
            <button
              type="button"
              className="btn btn-danger mt-3 ml-3"
              onClick={() => setSelectedAssureur(null)}
              disabled={isLocked}
            >
              <FontAwesomeIcon icon={faTrash} /> Supprimer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssureurForm;
