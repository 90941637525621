// src/components/SuperAdmin/Gestion/Entreprise/EntrepriseForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faLock, faUnlock, faChevronRight, faChevronDown, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import AssuranceModal from './AssuranceModal';
import EntrepriseSite from './EntrepriseSite'; // Import the new component for displaying users
import axiosInstance from '../../../../API/axiosInstance'; 

const EntrepriseForm = ({ selectedCompany, setSelectedCompany, setMessage, updateCompanyList }) => {
  const [formData, setFormData] = useState({
    name: '',
    siret: '',
    secteurId: '',
    statut: '',
    adminEmail: '',
    adminFirstName: '',
    adminLastName: '',
    sites: []
  });
  const [expandedSites, setExpandedSites] = useState({});
  const [expandedAdminDetails, setExpandedAdminDetails] = useState(false);
  const [secteurs, setSecteurs] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const [siteStatuses, setSiteStatuses] = useState([]); // Liste des statuts pour les sites
  const [isLocked, setIsLocked] = useState(true);
  const [localMessage, setLocalMessage] = useState('');
  const [showAddSiteForm, setShowAddSiteForm] = useState(false); // State to toggle add site form
  const [newSite, setNewSite] = useState({
    name: '',
    status_id: '',
    rue: '',
    code_postal: '',
    ville: '',
    région: '',
    pays: ''
  });
  const [showAssuranceModal, setShowAssuranceModal] = useState(false);
  const [selectedSiteForAssurances, setSelectedSiteForAssurances] = useState(null);
  const [siteAssurances, setSiteAssurances] = useState({});

  // Charger les secteurs
  const fetchSecteurs = async () => {
    try {
      const response = await axiosInstance.get('/secteurs/get-secteurs');
      // const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
      setSecteurs(response.data.secteurs);
    } catch (error) {
      console.error('Erreur lors de la récupération des secteurs:', error);
    }
  };

  // Charger les statuts pour l'entreprise et les sites
  const fetchStatuts = async () => {
    try {
      const response = await axiosInstance.get('/status/get-statuses');
      // const response = await axios.get('http://localhost:5000/api/status/get-statuses');
      setStatuts(response.data.statuses);
      setSiteStatuses(response.data.statuses); // Utilisation pour les statuts des sites également
    } catch (error) {
      console.error('Erreur lors de la récupération des statuts:', error);
    }
  };

  // Charger les données de l'entreprise sélectionnée
  const fetchCompanyData = useCallback(async () => {
    if (selectedCompany) {
        try {
            const siteResponse = await axiosInstance.get(`/entreprises/get-sites/${selectedCompany.id}`);
            // const siteResponse = await axios.get(`http://localhost:5000/api/entreprises/get-sites/${selectedCompany.id}`);
            setFormData({
                name: selectedCompany.name,
                siret: selectedCompany.siret,
                secteurId: selectedCompany.secteur_id,
                statut: selectedCompany.status_id,
                adminEmail: selectedCompany.admin_email || '',
                adminFirstName: selectedCompany.admin_first_name || '',
                adminLastName: selectedCompany.admin_last_name || '',
                sites: siteResponse.data.sites
            });
        } catch (error) {
            console.error("Erreur lors de la récupération des sites de l'entreprise:", error);
        }
    }
  }, [selectedCompany]);

  useEffect(() => {
    fetchSecteurs();
    fetchStatuts();
    fetchCompanyData();
  }, [fetchCompanyData]);

  const toggleLock = () => setIsLocked(!isLocked);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Toggle to show/hide the new site form
  const toggleAddSiteForm = () => {
    setShowAddSiteForm((prev) => !prev);
    setNewSite({
      name: '',
      status_id: '',
      rue: '',
      code_postal: '',
      ville: '',
      région: '',
      pays: ''
    });
  };

  // Handle changes in the new site form
  const handleNewSiteChange = (e) => {
    const { name, value } = e.target;
    setNewSite((prevSite) => ({ ...prevSite, [name]: value }));
  };

  // Add new site to formData.sites and reset new site form
// EntrepriseForm.js

const addNewSite = async () => {
  try {
    const response = await axiosInstance.post('/entreprises/add-site', {
    // const response = await axios.post('http://localhost:5000/api/entreprises/add-site', {
      companyId: selectedCompany.id,
      name: newSite.name,
      status_id: newSite.status_id,
      address: {
        rue: newSite.rue,
        code_postal: newSite.code_postal,
        ville: newSite.ville,
        région: newSite.région,
        pays: newSite.pays,
      },
    });

    // Ajoute le site localement si l'ajout a réussi
    setFormData((prevData) => ({
      ...prevData,
      sites: [...prevData.sites, response.data.newSite],
    }));

    setMessage('Site ajouté avec succès !');
    toggleAddSiteForm();  // Ferme le formulaire de site
  } catch (error) {
    console.error("Erreur lors de l'ajout du site:", error);
    setLocalMessage("Erreur lors de l'ajout du site.");
  }
};


  // Sauvegarder les modifications de l'entreprise
  const handleSave = async () => {
    try {
      const response = await axiosInstance.put('/entreprises/update-entreprise', {
      // await axios.put('http://localhost:5000/api/entreprises/update-entreprise', {
          id: selectedCompany.id,
          name: formData.name,
          siret: formData.siret,
          secteur_id: formData.secteurId,
          status_id: formData.statut,
          admin: {
              id: selectedCompany.admin_id, 
              email: formData.adminEmail,
              first_name: formData.adminFirstName,
              last_name: formData.adminLastName,
          },
          sites: formData.sites
      });
      setMessage('Entreprise mise à jour avec succès !');
      updateCompanyList();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'entreprise:", error);
      setLocalMessage("Erreur lors de la mise à jour de l'entreprise.");
    }
  };

  const toggleSiteExpansion = (siteId) => {
    setExpandedSites((prevState) => ({ ...prevState, [siteId]: !prevState[siteId] }));
    if (!siteAssurances[siteId]) {
      fetchLinkedAssurances(siteId);
    }
  };

  const fetchLinkedAssurances = async (siteId) => {
    try {
      const response = await axiosInstance.get(`/sites/${siteId}/linked-assurances`);
      // const response = await axios.get(`http://localhost:5000/api/sites/${siteId}/linked-assurances`);
      setSiteAssurances((prev) => ({ ...prev, [siteId]: response.data }));
    } catch (error) {
      console.error('Erreur lors de la récupération des assurances pour le site:', error);
    }
  };

  const toggleAdminExpansion = () => setExpandedAdminDetails(!expandedAdminDetails);

  const openAssuranceModal = (siteId) => {
    setSelectedSiteForAssurances(siteId);
    setShowAssuranceModal(true);
  };

  const closeAssuranceModal = () => {
    setShowAssuranceModal(false);
    setSelectedSiteForAssurances(null);
  };
  

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Informations de l'entreprise</h6>
          <button className="btn btn-secondary" onClick={toggleLock}>
            <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />
          </button>
        </div>
        <div className="card-body">
          {localMessage && <div className="alert alert-danger">{localMessage}</div>}

          <form>
            <h5>Informations de l'Entreprise</h5>
            <div className="form-group">
              <label>Nom de l'Entreprise</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                disabled={isLocked}
                required
              />
            </div>
            <div className="form-group">
              <label>Numéro de SIRET</label>
              <input
                type="text"
                className="form-control"
                name="siret"
                value={formData.siret}
                onChange={handleChange}
                disabled={isLocked}
                required
              />
            </div>
            <div className="form-group">
              <label>Secteur</label>
              <select
                className="form-control"
                name="secteurId"
                value={formData.secteurId}
                onChange={handleChange}
                disabled={isLocked}
                required
              >
                <option value="">Sélectionner un secteur</option>
                {secteurs.map((sec) => (
                  <option key={sec.id} value={sec.id}>{sec.nom}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Statut</label>
              <select
                className="form-control"
                name="statut"
                value={formData.statut}
                onChange={handleChange}
                disabled={isLocked}
                required
              >
                <option value="">Sélectionner un statut</option>
                {statuts.map((status) => (
                  <option key={status.id} value={status.id}>{status.name}</option>
                ))}
              </select>
            </div>

            <h5 className="mt-4">Informations de l'Administrateur</h5>
            <div
              className="d-flex justify-content-between align-items-center mb-2"
              onClick={toggleAdminExpansion}
              style={{ cursor: 'pointer' }}
            >
              <div>
                {formData.adminLastName} {formData.adminFirstName}
              </div>
              <FontAwesomeIcon icon={expandedAdminDetails ? faChevronDown : faChevronRight} />
            </div>
            {expandedAdminDetails && (
              <div className="admin-extra-info">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="adminEmail"
                    value={formData.adminEmail}
                    onChange={handleChange}
                    disabled={isLocked}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Prénom</label>
                  <input
                    type="text"
                    className="form-control"
                    name="adminFirstName"
                    value={formData.adminFirstName}
                    onChange={handleChange}
                    disabled={isLocked}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    name="adminLastName"
                    value={formData.adminLastName}
                    onChange={handleChange}
                    disabled={isLocked}
                    required
                  />
                </div>
              </div>
            )}

            <h5 className="mt-4">Sites de l'Entreprise</h5>
            {formData.sites.length > 0 ? (
              formData.sites.map((site, index) => (
                <div key={`site-${site.id || index}`} className="card mt-3">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <span>{site.name}</span>
                    <span>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openAssuranceModal(site.id);
                        }}
                        style={{ marginRight: '10px', textDecoration: 'underline', color: 'blue' }}
                      >
                        Ajouter une assurance
                      </a>
                    </span>
                    <FontAwesomeIcon
                      icon={expandedSites[site.id] ? faChevronDown : faChevronRight}
                      onClick={() => toggleSiteExpansion(site.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {expandedSites[site.id] && (
                    <div className="card-body">
                      <div className="form-group">
                        <label>Rue</label>
                        <input
                          type="text"
                          className="form-control"
                          value={site.rue}
                          onChange={(e) => {
                            const updatedSites = [...formData.sites];
                            updatedSites[index].rue = e.target.value;
                            setFormData({ ...formData, sites: updatedSites });
                          }}
                          disabled={isLocked}
                        />
                      </div>
                      <div className="form-group">
                        <label>Code Postal</label>
                        <input
                          type="text"
                          className="form-control"
                          value={site.code_postal}
                          onChange={(e) => {
                            const updatedSites = [...formData.sites];
                            updatedSites[index].code_postal = e.target.value;
                            setFormData({ ...formData, sites: updatedSites });
                          }}
                          disabled={isLocked}
                        />
                      </div>
                      <div className="form-group">
                        <label>Ville</label>
                        <input
                          type="text"
                          className="form-control"
                          value={site.ville}
                          onChange={(e) => {
                            const updatedSites = [...formData.sites];
                            updatedSites[index].ville = e.target.value;
                            setFormData({ ...formData, sites: updatedSites });
                          }}
                          disabled={isLocked}
                        />
                      </div>
                      <div className="form-group">
                        <label>Région</label>
                        <input
                          type="text"
                          className="form-control"
                          value={site.région}
                          onChange={(e) => {
                            const updatedSites = [...formData.sites];
                            updatedSites[index].région = e.target.value;
                            setFormData({ ...formData, sites: updatedSites });
                          }}
                          disabled={isLocked}
                        />
                      </div>
                      <div className="form-group">
                        <label>Statut</label>
                        <select
                          className="form-control"
                          value={site.status_id}
                          onChange={(e) => {
                            const updatedSites = [...formData.sites];
                            updatedSites[index].status_id = e.target.value;
                            setFormData({ ...formData, sites: updatedSites });
                          }}
                          disabled={isLocked}
                        >
                          <option value="">Sélectionner un statut</option>
                          {siteStatuses.map((status) => (
                            <option key={status.id} value={status.id}>{status.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Pays</label>
                        <input
                          type="text"
                          className="form-control"
                          value={site.pays}
                          onChange={(e) => {
                            const updatedSites = [...formData.sites];
                            updatedSites[index].pays = e.target.value;
                            setFormData({ ...formData, sites: updatedSites });
                          }}
                          disabled={isLocked}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>Aucun site trouvé pour cette entreprise.</p>
            )}

            {/* Toggle add site form */}
            <div className="mt-4">
              <button
                type="button"
                className="btn btn-success"
                onClick={toggleAddSiteForm}
                disabled={isLocked}
              >
                <FontAwesomeIcon icon={faPlus} /> Ajouter un site
              </button>
            </div>

            {showAddSiteForm && (
              <div className="mt-3 p-3 border rounded">
                <h6>Ajouter un Nouveau Site</h6>
                <div className="form-group">
                  <label>Nom du Site</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={newSite.name}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  />
                </div>
                <div className="form-group">
                  <label>Statut du Site</label>
                  <select
                    className="form-control"
                    name="status_id"
                    value={newSite.status_id}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  >
                    <option value="">Sélectionner un statut</option>
                    {siteStatuses.map((status) => (
                      <option key={status.id} value={status.id}>{status.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Adresse du Site</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Rue"
                    name="rue"
                    value={newSite.rue}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Code Postal"
                    name="code_postal"
                    value={newSite.code_postal}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Ville"
                    name="ville"
                    value={newSite.ville}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Région"
                    name="région"
                    value={newSite.région}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Pays"
                    name="pays"
                    value={newSite.pays}
                    onChange={handleNewSiteChange}
                    disabled={isLocked}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-secondary mt-3"
                  onClick={addNewSite}
                  disabled={isLocked}
                >
                  Enregistrer le site
                </button>
              </div>
            )}

            <div className="d-flex justify-content-between mt-4">
              <button type="button" className="btn btn-primary" onClick={handleSave} disabled={isLocked}>
                <FontAwesomeIcon icon={faSave} /> Sauvegarder
              </button>
              <button type="button" className="btn btn-danger" onClick={() => setSelectedCompany(null)} disabled={isLocked}>
                <FontAwesomeIcon icon={faTrash} /> Supprimer
              </button>
            </div>
          </form>
        </div>
      </div>

      <AssuranceModal
        showModal={showAssuranceModal}
        closeModal={closeAssuranceModal}
        selectedSite={selectedSiteForAssurances}
      />

      {/* Add the EntrepriseSite component here to show users for the company's sites */}
      <EntrepriseSite selectedCompany={selectedCompany} />
    </div>
  );
};

export default EntrepriseForm;
