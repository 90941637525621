import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../API/axiosInstance'; 

const SecteurAjout = ({ fetchSecteurs }) => {
  const [nom, setNom] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //const response = await axios.post('http://localhost:5000/api/entreprises/secteurs', { nom });
      const response = await axiosInstance.post('/entreprises/secteurs', { nom });
      if (response.status === 201) {
        setMessage('Secteur ajouté avec succès dans la base de données !');
        setNom('');
        fetchSecteurs(); // Met à jour la liste des secteurs
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du secteur :", error);
      setMessage("Erreur lors de l'ajout du secteur.");
    }
  };

  // Réinitialise le message après 3 secondes
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Ajouter un Secteur</h6>
      </div>
      <div className="card-body">
        {message && (
          <div className={`alert ${message.includes('succès') ? 'alert-success' : 'alert-danger'}`}>
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nom du secteur</label>
            <input
              type="text"
              className="form-control"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">Ajouter</button>
        </form>
      </div>
    </div>
  );
};

export default SecteurAjout;
