// src/Ajouts/Entreprise/EntrepriseAjout.js
import React, { useState, useEffect } from 'react';
import SecteurAjout from '../Secteur/SecteurAjout';
import axiosInstance from '../../../../API/axiosInstance'; 

const EntrepriseAjout = () => {
  const [name, setName] = useState('');
  const [siret, setSiret] = useState('');
  const [secteurId, setSecteurId] = useState('');
  const [status, setStatus] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [adminFirstName, setAdminFirstName] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [siteName, setSiteName] = useState('');
  const [siteStatus, setSiteStatus] = useState('');
  const [siteAddress, setSiteAddress] = useState({
    rue: '',
    code_postal: '',
    ville: '',
    région: '',
    pays: ''
  });
  const [siteList, setSiteList] = useState([]);
  const [message, setMessage] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  // Fonction pour récupérer les secteurs
  const fetchSecteurs = async () => {
    try {
      const response = await axiosInstance.get('/secteurs/get-secteurs');
      // const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
      setSecteurs(response.data.secteurs || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des secteurs :", error);
    }
  };

  // Charger les secteurs et les statuts au chargement
  useEffect(() => {
    fetchSecteurs();

    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/status/get-statuses');
        // const response = await axios.get('http://localhost:5000/api/status/get-statuses');
        setStatuses(response.data.statuses || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des statuts :", error);
      }
    };

    fetchStatuses();
  }, []);

  // Ajouter ou modifier un site dans la liste des sites
  const handleAddOrEditSite = () => {
    const siteData = { 
      name: siteName, 
      status_id: siteStatus || null,
      address: siteAddress 
    };
    
    if (editIndex !== null) {
      setSiteList((prevList) =>
        prevList.map((site, index) => (index === editIndex ? siteData : site))
      );
      setEditIndex(null);
    } else {
      setSiteList([...siteList, siteData]);
    }
    resetSiteForm();
  };

  // Gestion des changements dans l'adresse du site
  const handleSiteAddressChange = (e) => {
    const { name, value } = e.target;
    setSiteAddress((prev) => ({ ...prev, [name]: value }));
  };

  // Réinitialiser les champs de site
  const resetSiteForm = () => {
    setSiteName('');
    setSiteStatus('');
    setSiteAddress({ rue: '', code_postal: '', ville: '', région: '', pays: '' });
  };

  // Réinitialiser le formulaire de l'entreprise
  const resetForm = () => {
    setName('');
    setSiret('');
    setSecteurId('');
    setStatus('');
    setAdminEmail('');
    setAdminPassword('');
    setAdminFirstName('');
    setAdminLastName('');
    setSiteList([]);
    resetSiteForm();
  };

  // Soumission du formulaire de création d'entreprise
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = {
      name,
      siret,
      secteur_id: secteurId,
      status_id: status,
      admin: {
        email: adminEmail,
        password: adminPassword,
        first_name: adminFirstName,
        last_name: adminLastName,
      },
      sites: siteList,
    };
  
    try {
      const response = await axiosInstance.post('/entreprises/add-entreprise', data);
      //const response = await axios.post('http://localhost:5000/api/entreprises/add-entreprise', data);
      if (response.status === 201) {
        setMessage('Entreprise ajoutée avec succès !');
        resetForm();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'entreprise :", error);
      setMessage("Erreur lors de l'ajout de l'entreprise.");
    }
  };

  // Fonction pour éditer un site existant dans la liste
  const handleEditSite = (index) => {
    const site = siteList[index];
    setSiteName(site.name);
    setSiteStatus(site.status_id); 
    setSiteAddress(site.address);
    setEditIndex(index);
  };

  // Fonction pour supprimer un site de la liste
  const handleDeleteSite = (index) => {
    setSiteList(siteList.filter((_, i) => i !== index));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Formulaire d'ajout d'entreprise */}
        <div className="col-md-8">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Ajouter une Entreprise</h6>
            </div>
            <div className="card-body">
              {message && (
                <div className={`alert ${message.includes('succès') ? 'alert-success' : 'alert-danger'}`}>
                  {message}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <h5>Informations de l'Entreprise</h5>
                <div className="form-group mb-3">
                  <label>Nom de l'Entreprise</label>
                  <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="form-group mb-3">
                  <label>Numéro de SIRET</label>
                  <input type="text" className="form-control" value={siret} onChange={(e) => setSiret(e.target.value)} required />
                </div>
                <div className="form-group mb-3">
                  <label>Secteur de l'Entreprise</label>
                  <select className="form-control" value={secteurId} onChange={(e) => setSecteurId(e.target.value)} required>
                    <option value="">Sélectionnez un secteur</option>
                    {secteurs.map((secteur, index) => (
                      <option key={`secteur-${secteur.id}-${index}`} value={secteur.id}>
                        {secteur.nom}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>Statut</label>
                  <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} required>
                    <option value="">Sélectionnez un statut</option>
                    {statuses.map((statut, index) => (
                      <option key={`status-${statut.id}-${index}`} value={statut.id}>
                        {statut.name}
                      </option>
                    ))}
                  </select>
                </div>

                <h5 className="mt-4">Informations de l'Administrateur</h5>
                <div className="form-row d-flex justify-content-between mb-3">
                  <div className="form-group col-md-6">
                    <label htmlFor="adminFirstName">Prénom de l'Administrateur</label>
                    <input
                      type="text"
                      className="form-control"
                      id="adminFirstName"
                      value={adminFirstName}
                      onChange={(e) => setAdminFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="adminLastName">Nom de l'Administrateur</label>
                    <input
                      type="text"
                      className="form-control"
                      id="adminLastName"
                      value={adminLastName}
                      onChange={(e) => setAdminLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Email de l'Administrateur</label>
                  <input
                    type="email"
                    className="form-control"
                    value={adminEmail}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Mot de Passe de l'Administrateur</label>
                  <input
                    type="password"
                    className="form-control"
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                    required
                  />
                </div>

                <h5 className="mt-4">Informations du Site</h5>
                <div className="form-group mb-3">
                  <label>Nom du Site</label>
                  <input type="text" className="form-control" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
                </div>
                <div className="form-group mb-3">
                  <label>Statut du Site</label>
                  <select className="form-control" value={siteStatus} onChange={(e) => setSiteStatus(e.target.value)}>
                    <option value="">Sélectionnez un statut</option>
                    {statuses.map((statut, index) => (
                      <option key={`site-status-${statut.id}-${index}`} value={statut.id}>
                        {statut.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>Adresse du Site</label>
                  <input type="text" className="form-control" placeholder="Rue" name="rue" value={siteAddress.rue} onChange={handleSiteAddressChange} />
                  <input type="text" className="form-control mt-2" placeholder="Code Postal" name="code_postal" value={siteAddress.code_postal} onChange={handleSiteAddressChange} />
                  <input type="text" className="form-control mt-2" placeholder="Ville" name="ville" value={siteAddress.ville} onChange={handleSiteAddressChange} />
                  <input type="text" className="form-control mt-2" placeholder="Région" name="région" value={siteAddress.région} onChange={handleSiteAddressChange} />
                  <input type="text" className="form-control mt-2" placeholder="Pays" name="pays" value={siteAddress.pays} onChange={handleSiteAddressChange} />
                </div>
                <button type="button" className="btn btn-secondary mt-3" onClick={handleAddOrEditSite}>
                  {editIndex !== null ? 'Modifier le Site' : 'Ajouter le Site'}
                </button>

                <ul className="list-group mt-3">
                  {siteList.map((site, index) => (
                    <li key={`site-${index}`} className="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <strong>{site.name}</strong> - {site.address.rue}, {site.address.code_postal}, {site.address.ville}, {site.address.région}, {site.address.pays}, 
                        Statut: {statuses.find(s => s.id === parseInt(site.status_id))?.name || 'N/A'}
                      </div>
                      <div>
                        <button className="btn btn-sm btn-warning mr-2" onClick={() => handleEditSite(index)}>
                          Modifier
                        </button>
                        <button className="btn btn-sm btn-danger" onClick={() => handleDeleteSite(index)}>
                          Supprimer
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>

                <button type="submit" className="btn btn-primary mt-4">Ajouter Entreprise</button>
              </form>
            </div>
          </div>
        </div>

        {/* Formulaire d'ajout de secteur */}
        <div className="col-md-4">
          <SecteurAjout fetchSecteurs={fetchSecteurs} />
        </div>
      </div>
    </div>
  );
};

export default EntrepriseAjout;
