import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../API/axiosInstance'; 

const EntrepriseSite = ({ selectedCompany }) => {
  const [sites, setSites] = useState([]);
  const [siteUsers, setSiteUsers] = useState({ admins: [], managers: [], vendors: [] });
  const [expandedManagers, setExpandedManagers] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('all');
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCompany) {
      fetchCompanySites(selectedCompany.id);
    } else {
      setSites([]);
      setSiteUsers({ admins: [], managers: [], vendors: [] });
      setExpandedManagers({});
      setSelectedSiteId('');
    }
  }, [selectedCompany]);

  const fetchCompanySites = async (companyId) => {
    try {
      const response = await axiosInstance.get(`entreprises/get-site-users/${companyId}`);
      // const response = await axios.get(`http://localhost:5000/api/entreprises/get-site-users/${companyId}`);
      if (response.data && Array.isArray(response.data.sites)) {
        setSites(response.data.sites);

        // Utiliser un Set pour éviter les duplications de vendeurs
        const uniqueVendors = new Set();
        const vendors = response.data.sites.flatMap(site =>
          site.vendors.map(vendor => {
            const vendorKey = `${vendor.id}-${site.site_id}`; // Associe l'ID du vendeur avec le site pour un identifiant unique
            if (!uniqueVendors.has(vendorKey)) {
              uniqueVendors.add(vendorKey);
              return { ...vendor, site_id: site.site_id };
            }
            return null;
          }).filter(Boolean) // Supprime les null pour garder uniquement les vendeurs uniques
        );

        setSiteUsers({
          admins: response.data.admins,
          managers: response.data.sites.flatMap(site =>
            site.managers.map(manager => ({ ...manager, site_id: site.site_id }))
          ),
          vendors: vendors
        });
      } else {
        console.error("Structure de réponse inattendue:", response.data);
        setSites([]);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des sites de l'entreprise:", error);
    }
};

  const handleSiteChange = (e) => {
    setSelectedSiteId(e.target.value);
    setExpandedManagers({});
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setExpandedManagers({});
  };

  const toggleManagerExpansion = (managerId) => {
    setExpandedManagers((prev) => ({
      ...prev,
      [managerId]: !prev[managerId]
    }));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterUsers = (users) => {
    return users.filter(user => {
      const matchesSite = !selectedSiteId || user.site_id === parseInt(selectedSiteId);
      const matchesRole = selectedRole === 'all' || user.role === selectedRole;
      const matchesSearch = `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesSite && matchesRole && matchesSearch;
    });
  };

  const filteredAdmins = filterUsers(siteUsers.admins);
  const filteredManagers = filterUsers(siteUsers.managers);
  const filteredVendors = filterUsers(siteUsers.vendors);

  const handleUserDetails = (userId) => {
    navigate(`/superadmin-panel/manage-users/${userId}`);
  };

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3 d-flex justify-content-between align-items-center">
        <h6 className="m-0 font-weight-bold text-primary">Effectifs</h6>
        <div className="d-flex align-items-center" style={{ gap: '10px' }}>
          <select className="form-control" onChange={handleSiteChange} value={selectedSiteId}>
            <option value="">Tous les sites</option>
            {sites.map(site => (
              <option key={`site-${site.site_id}`} value={site.site_id}>{site.site_name}</option>
            ))}
          </select>
          <select className="form-control" onChange={handleRoleChange} value={selectedRole}>
            <option value="all">Tous les rôles</option>
            <option value="admin">Admins</option>
            <option value="manager">Managers</option>
            <option value="vendor">Vendors</option>
          </select>
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher un nom"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="card-body">
        {filteredAdmins.length > 0 && selectedRole !== 'vendor' && (
          <div className="mt-3">
            {filteredAdmins.map(admin => (
              <div key={`admin-${admin.id}`} className="card mt-2">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span><span className="font-weight-bold">{`${admin.first_name} ${admin.last_name}`}</span> {`(${admin.role})`}</span>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon 
                      icon={faEye} 
                      onClick={() => handleUserDetails(admin.id)} 
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-3">
          {filteredManagers.map(manager => (
            <div key={`manager-${manager.id}`} className="card mt-2">
              <div className="card-header d-flex justify-content-between align-items-center">
                <span><span className="font-weight-bold">{`${manager.first_name} ${manager.last_name}`}</span> {`(${manager.role})`}</span>
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon 
                    icon={faEye} 
                    onClick={() => handleUserDetails(manager.id)} 
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                  />
                  <button 
                    className="btn btn-link" 
                    onClick={() => toggleManagerExpansion(manager.id)}
                  >
                    <FontAwesomeIcon icon={expandedManagers[manager.id] ? faChevronUp : faChevronDown} />
                  </button>
                </div>
              </div>
              {expandedManagers[manager.id] && (
                <div className="card-body pt-2 pb-2">
                  <ul className="list-group">
                  {filteredVendors.filter(vendor => vendor.managerId === manager.id).map((vendor, index) => (
                    <li 
                      key={`vendor-${vendor.id}-${index}`} 
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={searchTerm && `${vendor.first_name} ${vendor.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ? { backgroundColor: '#ffffcc' } : {}}
                    >
                      <span><span className="font-weight-bold">{`${vendor.first_name} ${vendor.last_name}`}</span> {`(vendor)`}</span>
                      <FontAwesomeIcon 
                        icon={faEye} 
                        onClick={() => handleUserDetails(vendor.id)} 
                        style={{ cursor: 'pointer' }}
                      />
                    </li>
                  ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        {selectedRole === 'vendor' && filteredVendors.length > 0 && (
          <div className="mt-3">
            {filteredVendors.map(vendor => (
              <div key={`vendor-direct-${vendor.id}`} className="card mt-2">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span><span className="font-weight-bold">{`${vendor.first_name} ${vendor.last_name}`}</span> {`(vendor) - Manager: ${vendor.manager_first_name} ${vendor.manager_last_name}`}</span>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon 
                      icon={faEye} 
                      onClick={() => handleUserDetails(vendor.id)} 
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EntrepriseSite;
