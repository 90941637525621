import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SuperAdminLayout from './components/SuperAdmin/Layout/SuperAdminLayout';
import VendorLayout from './components/Vendor/Layout/VendorLayout';
import VendorLogin from './components/Vendor/Login/VendorLogin';
import SuperAdminLogin from './components/SuperAdmin/Login/SuperAdminLogin';
import './App.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isSuperAdminAuthenticated, setIsSuperAdminAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      const superadminToken = localStorage.getItem('superadminToken');
      
      if (token) {
        try {
          const payload = JSON.parse(atob(token.split('.')[1]));
          const now = Math.floor(Date.now() / 1000);
          setIsAuthenticated(payload.exp && payload.exp > now);
        } catch (error) {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }

      if (superadminToken) {
        try {
          const payload = JSON.parse(atob(superadminToken.split('.')[1]));
          const now = Math.floor(Date.now() / 1000);
          setIsSuperAdminAuthenticated(payload.exp && payload.exp > now);
        } catch (error) {
          localStorage.removeItem('superadminToken');
          setIsSuperAdminAuthenticated(false);
        }
      } else {
        setIsSuperAdminAuthenticated(false);
      }
    };

    checkAuth();
    const intervalId = setInterval(checkAuth, 10000);
    return () => clearInterval(intervalId);
  }, []);

  if (isAuthenticated === null || isSuperAdminAuthenticated === null) {
    return <p>Chargement...</p>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/superadmin-login"
          element={<SuperAdminLogin setIsAuthenticated={setIsSuperAdminAuthenticated} />}
        />
        <Route
          path="/superadmin-panel/*"
          element={
            isSuperAdminAuthenticated ? <SuperAdminLayout /> : <Navigate to="/superadmin-login" />
          }
        />
        <Route
          path="/vendor-panel/*"
          element={isAuthenticated ? <VendorLayout /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={<VendorLogin setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route path="*" element={<Navigate to="/superadmin-panel/manage-company" />} />
      </Routes>
    </Router>
  );
};

export default App;
