import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './ParcoursClient.module.css';
import axiosInstance from '../../../API/axiosInstance'; 

const Recapitulatif = ({ formData, vendorId }) => {
  const { t } = useTranslation();
  const {
    bikeType,
    marque,
    modele,
    prixAchat,
    dateAchat,
    proprieteVelo,
    deplacementType,
    nom,
    prenom,
    email,
    telephone,
    adresse,
    ville,
    codePostal,
    selectedAssurance,
  } = formData;

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedItems = [];
      if (bikeType) {
        // Correction pour `type_deplacement`
        const deplacementMapped = deplacementType.toLowerCase() === 'particuliers' ? 'particulier' : 
                                  deplacementType.toLowerCase() === 'professionnels' ? 'professionnel' : 
                                  deplacementType.toLowerCase() === 'les deux' ? 'les deux' : deplacementType;
  
        selectedItems.push({
          type: 'velo',
          type_velo: bikeType, // Ajoutez la clé type_velo pour correspondre aux valeurs attendues
          marque,
          modele,
          prix_achat: prixAchat,
          date_achat: `${dateAchat.year}-${dateAchat.month}-${dateAchat.day}`,
          propriete: proprieteVelo,
          deplacement: deplacementMapped, // Utilisation de la valeur corrigée
        });
      }
      if (selectedAssurance) {
        selectedItems.push({ type: 'assurance', id: selectedAssurance.id });
      }
  
      console.log("selectedItems sent to backend:", selectedItems); // Log pour vérifier `selectedItems`
  
      const clientResponse = await axiosInstance.post('/ajouter-client', {
        nom,
        prenom,
        email,
        telephone,
        adresse,
        ville,
        code_postal: codePostal,
        selectedItems,
        vendor_id: vendorId,
      });
  
      const { clientId } = clientResponse.data;
      if (!clientId) throw new Error("Erreur : clientId non retourné par l'API.");
  
      const transactionResponse = await axiosInstance.post('/start-transaction', {
        clientId,
        vendor_id: vendorId,
      });
  
      const { transactionId } = transactionResponse.data;
      if (!transactionId) throw new Error("Erreur : transactionId non retourné par l'API.");
  
      navigate('/vendor-panel/loading-payment', { state: { transactionId } });
    } catch (error) {
      setError(error.response ? error.response.data.message : "Erreur lors de l'ajout des données.");
    } finally {
      setIsLoading(false);
    }
  };
  
  

  return (
    <div className={`${styles.container} ${styles.my4}`}>
      <h2 className={`${styles.titleHeading} text-center mb-4`}>{t('ParcourClient.question8.summaryofinformation')}</h2>
      {error && <p className={`text-center ${styles.textDanger}`}>{error}</p>}

      <div className={`${styles.recapCard} ${styles.shadow} ${styles.recapCardContainer}`}>
        {bikeType && (
          <div className={styles.recapSection}>
            <h4>{t('ParcourClient.recap_content.bikeinfo')}</h4>
            <p><strong>{t('ParcourClient.recap_content.type')} :</strong> {bikeType}</p>
            <p><strong>{t('ParcourClient.recap_content.brand')} :</strong> {marque}</p>
            <p><strong>{t('ParcourClient.recap_content.model')} :</strong> {modele}</p>
            <p><strong>{t('ParcourClient.recap_content.price')} :</strong> {prixAchat} €</p>
            <p><strong>{t('ParcourClient.recap_content.datebuy')} :</strong> {`${dateAchat.day}/${dateAchat.month}/${dateAchat.year}`}</p>
            <p><strong>{t('ParcourClient.recap_content.property')} :</strong> {proprieteVelo}</p>
          </div>
        )}

        {selectedAssurance && (
          <div className={styles.recapSection}>
            <h4>{t('ParcourClient.recap_content.selectedassurance')}</h4>
            <p><strong>{t('ParcourClient.recap_content.nameassurance')} :</strong> {selectedAssurance.nom}</p>
            <p><strong>{t('ParcourClient.recap_content.priceassurance')} :</strong> {selectedAssurance.prix} / {t('ParcourClient.option_date_purchase.month')}</p>
          </div>
        )}

        <div className={styles.recapSection}>
          <h4>{t('ParcourClient.recap_content.individuinfo')}</h4>
          <p><strong>{t('ParcourClient.recap_content.name')} :</strong> {nom}</p>
          <p><strong>{t('ParcourClient.recap_content.surname')} :</strong> {prenom}</p>
          <p><strong>{t('ParcourClient.recap_content.email')} :</strong> {email}</p>
          <p><strong>{t('ParcourClient.recap_content.phone')} :</strong> {telephone}</p>
          <p><strong>{t('ParcourClient.recap_content.adress')} :</strong> {adresse}, {ville}, {codePostal}</p>
        </div>
      </div>

      <div className="text-center mt-4">
        <button 
          className={styles.nextStepBtn}
          onClick={handleSubmit} 
        >
          {t('Button.final')}
        </button>
      </div>
    </div>
  );
};

export default Recapitulatif;
