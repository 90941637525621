import React, { useEffect, useState } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Navbar from '../VendorNavbar';
import VendeurAccueil from '../Accueil/VendeurAccueil';
import HistoriqueTransactions from '../Historique/HistoriqueTransactions';
import ParcoursClient from '../ParcoursClient/ParcoursClient';
import Assurances from '../Assurances/Assurances';
import LoadingPayment from '../Load/LoadingPayment';
import styles from './global.module.css';

const VendorLayout = () => {
  const [vendorName, setVendorName] = useState(localStorage.getItem('vendorName') || '');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add(styles.globalContainer);

    return () => {
      document.body.classList.remove(styles.globalContainer);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const now = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp < now) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        const displayName = `${decodedToken.last_name}.${decodedToken.first_name.charAt(0)}`;
        setVendorName(displayName);
        localStorage.setItem('vendorName', displayName); // Sauvegarde dans le localStorage
      }
    } catch (error) {
      localStorage.removeItem('token');
      navigate('/login');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  // Sauvegarde dans localStorage à chaque mise à jour de vendorName
  useEffect(() => {
    if (vendorName) {
      localStorage.setItem('vendorName', vendorName);
    }
  }, [vendorName]);

  if (loading) {
    return <p>Chargement...</p>;
  }

  return (
    <div>
      <Navbar vendorName={vendorName} />
      <div className={styles['content-wrapper']} style={{ padding: '20px', flexGrow: 1 }}>
        <Routes>
          <Route index element={<VendeurAccueil />} />
          <Route path="historique" element={<HistoriqueTransactions />} />
          <Route path="parcours-client" element={<ParcoursClient />} />
          <Route path="assurances" element={<Assurances />} />
          <Route path="loading-payment" element={<LoadingPayment />} />
        </Routes>
      </div>
    </div>
  );
};

export default VendorLayout;
