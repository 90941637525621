import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ParcoursClient.module.css';

import VAEImage from '../../../assets/images/vendor/velo_electrique.png';
import ClassicBikeImage from '../../../assets/images/vendor/velo_classique.png';
import CargoBikeImage from '../../../assets/images/vendor/velo_cargo.png';

const BikeSelection = ({ setFormData, setCurrentStep }) => {
  const { t } = useTranslation();

  const handleSelection = (bikeType) => {
    console.log("Bike type selected:", bikeType); // Ajout de vérification de la valeur
    setFormData((prevData) => ({
      ...prevData,
      bikeType,
    }));
    setCurrentStep(2); 
  };

  return (
    <div>
      <h2 className={`text-center mb-4 ${styles.titleHeading}`}>
        {t('ParcourClient.questions.type_bike')}
      </h2>
      <div className="d-flex flex-column align-items-center">
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('vae')}
        >
          <img src={VAEImage} alt={t('ParcourClient.options_type_bike.electric_bike')} />
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{t('ParcourClient.options_type_bike.electric_bike')}</h5>
          </div>
        </div>
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('classique')}
        >
          <img src={ClassicBikeImage} alt={t('ParcourClient.options_type_bike.classic_bike')} />
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{t('ParcourClient.options_type_bike.classic_bike')}</h5>
          </div>
        </div>
        <div
          className={`${styles.hoverCard} card text-center p-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('cargo')}
        >
          <img src={CargoBikeImage} alt={t('ParcourClient.options_type_bike.cargo_bike')} />
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{t('ParcourClient.options_type_bike.cargo_bike')}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikeSelection;
