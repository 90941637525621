// src/components/SuperAdmin/Gestion/Assureur/AssureurSelect.js
import React, { useEffect, useState } from 'react';

import axiosInstance from '../../../../API/axiosInstance'; 

const AssureurSelect = ({
  selectedSecteur, selectedStatus,
  setSelectedSecteur, setSelectedStatus,
  searchTerm, setSearchTerm
}) => {
  const [secteurs, setSecteurs] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const secteurResponse = await axiosInstance.get('/secteurs/get-secteurs');
        //const secteurResponse = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
        setSecteurs(secteurResponse.data.secteurs || []);

        const statusResponse = await axiosInstance.get('/status/get-statuses');
        //const statusResponse = await axios.get('http://localhost:5000/api/status/get-statuses');
        setStatuses(statusResponse.data.statuses || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des options :", error);
        setMessage("Erreur lors de la récupération des options.");
      }
    };
    fetchOptions();
  }, []);

  return (
    <div className="card shadow mb-4 w-100">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Filtrer les assureurs</h6>
      </div>
      <div className="card-body">
        {message && (
          <div className={`alert ${message.includes('Erreur') ? 'alert-danger' : 'alert-success'}`}>
            {message}
          </div>
        )}
        <form>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Secteur</label>
              <select
                className="form-control"
                value={selectedSecteur}
                onChange={(e) => setSelectedSecteur(e.target.value)}
              >
                <option value="">Tous les secteurs</option>
                {secteurs.map(secteur => (
                  <option key={secteur.id} value={secteur.id}>{secteur.nom}</option>
                ))}
              </select>
            </div>

            <div className="col-md-4">
              <label>Statut</label>
              <select
                className="form-control"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Tous les statuts</option>
                {statuses.map(status => (
                  <option key={status.id} value={status.id}>{status.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md-4">
              <label>Rechercher</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom ou SIRET"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssureurSelect;
