import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SuperAdminSidebar from '../Sidebar/SuperAdminSidebar';
import SuperAdminNavbar from '../Navbar/SuperAdminNavbar';
import EntrepriseAjout from '../Ajouts/Entreprise/EntrepriseAjout';
import AssuranceAjout from '../Ajouts/Assurance/AssuranceAjout';
import AssureurAjout from '../Ajouts/Assureur/AssureurAjout';
import UserAjout from '../Ajouts/User/UserAjout';
import EntrepriseView from '../Gestion/Entreprise/EntrepriseView';
import AssuranceGestion from '../Gestion/Assurance/AssuranceView';
import UtilisateursView from '../Gestion/Utilisateur/UserView';
import AssureurView from '../Gestion/Assureur/AssureurView';

const SuperAdminLayout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsSidebarCollapsed(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const superadminToken = localStorage.getItem('superadminToken');
    if (!superadminToken) {
      navigate('/superadmin-login');
    }
  }, [navigate]);

  return (
    <div className="d-flex">
      <SuperAdminSidebar isCollapsed={isSidebarCollapsed} />
      <div className="flex-grow-1" style={{ marginLeft: isSidebarCollapsed ? '4.5rem' : '220px' }}>
        <SuperAdminNavbar />
        <div className="container-fluid" style={{ paddingTop: '85px', marginLeft: '0' }}>
          <Routes>
            <Route path="add-company" element={<EntrepriseAjout />} />
            <Route path="add-assurance" element={<AssuranceAjout />} />
            <Route path="add-assureur" element={<AssureurAjout />} />
            <Route path="add-user" element={<UserAjout />} />
            <Route path="manage-company" element={<EntrepriseView />} />
            <Route path="manage-assurance" element={<AssuranceGestion />} />
            <Route path="manage-users" element={<UtilisateursView />} />
            <Route path="manage-assureur" element={<AssureurView />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLayout;
