// src/components/SuperAdmin/Gestion/User/UserForm.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../../API/axiosInstance'; 

const UserForm = ({ selectedUser, setSelectedUser, updateUserList, setMessage }) => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [secteur, setSecteur] = useState('');
  const [secteurs, setSecteurs] = useState([]);
  const [entreprise, setEntreprise] = useState('');
  const [entreprises, setEntreprises] = useState([]);
  const [filteredEntreprises, setFilteredEntreprises] = useState([]);
  const [site, setSite] = useState('');
  const [sites, setSites] = useState([]);
  const [manager, setManager] = useState('');
  const [managers, setManagers] = useState([]);
  const [isLocked, setIsLocked] = useState(true);
  const [localMessage, setLocalMessage] = useState('');

  const toggleLock = () => setIsLocked(!isLocked);

  useEffect(() => {
    if (selectedUser) {
      const fetchUserDetails = async () => {
        try {
          const response = await axiosInstance.get(`/users/get-user-details/${selectedUser.id}`);
          // const response = await axios.get(`http://localhost:5000/api/users/get-user-details/${selectedUser.id}`);
          const user = response.data.user;

          setNom(user.first_name);
          setPrenom(user.last_name);
          setEmail(user.email);
          setRole(user.role);
          setSecteur(user.secteur_id || '');
          setEntreprise(user.entreprise_id || '');
          setSite(user.site_id || '');
          setManager(user.manager_id || '');

          if (user.secteur_id) setFilteredEntreprises(entreprises.filter(ent => ent.secteur_id === user.secteur_id));
          if (user.entreprise_id) {
            const responseSites = await axiosInstance.get(`/entreprises/get-sites/${user.entreprise_id}`);
            // const responseSites = await axios.get(`http://localhost:5000/api/entreprises/get-sites/${user.entreprise_id}`);
            setSites(responseSites.data.sites);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des informations de l'utilisateur :", error);
        }
      };
      fetchUserDetails();
    }
  }, [selectedUser, entreprises]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get('/users/get-roles');
        // const response = await axios.get('http://localhost:5000/api/users/get-roles');
        setRoles(response.data.roles);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles :", error);
      }
    };
    fetchRoles();

    const fetchSecteurs = async () => {
      try {
        const response = await axiosInstance.get('/secteurs/get-secteurs');
        // const response = await axios.get('http://localhost:5000/api/secteurs/get-secteurs');
        setSecteurs(response.data.secteurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des secteurs :", error);
      }
    };
    fetchSecteurs();

    const fetchEntreprises = async () => {
      try {
        const response = await axiosInstance.get('/entreprises/get-entreprises');
        // const response = await axios.get('http://localhost:5000/api/entreprises/get-entreprises');
        setEntreprises(response.data.entreprises);
      } catch (error) {
        console.error("Erreur lors de la récupération des entreprises :", error);
      }
    };
    fetchEntreprises();
  }, []);

  useEffect(() => {
    const filtered = entreprises.filter(ent => ent.secteur_id === parseInt(secteur));
    setFilteredEntreprises(filtered);
  }, [secteur, entreprises]);

  useEffect(() => {
    const fetchSites = async () => {
      if (entreprise) {
        try {
          const response = await axiosInstance.get(`/entreprises/get-sites/${entreprise}`);
          // const response = await axios.get(`http://localhost:5000/api/entreprises/get-sites/${entreprise}`);
          setSites(response.data.sites);
        } catch (error) {
          console.error("Erreur lors de la récupération des sites :", error);
          setSites([]);
        }
      } else {
        setSites([]);
      }
    };
    fetchSites();
  }, [entreprise]);

  useEffect(() => {
    const fetchManagers = async () => {
      if (role === 'vendor' && site) {
        try {
          const response = await axiosInstance.get(`/users/get-managers/${site}`);
          // const response = await axios.get(`http://localhost:5000/api/users/get-managers/${site}`);
          setManagers(response.data.managers);
        } catch (error) {
          console.error("Erreur lors de la récupération des managers :", error);
          setManagers([]);
        }
      } else {
        setManagers([]);
      }
    };
    fetchManagers();
  }, [role, site]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put('/users/update-user', {
      // await axios.put(`http://localhost:5000/api/users/update-user`, {
        id: selectedUser.id,
        first_name: nom,
        last_name: prenom,
        email,
        role,
        secteur_id: secteur,
        entreprise_id: entreprise,
        site_id: site,
        manager_id: role === 'vendor' ? manager : null,
      });

      setMessage("Utilisateur mis à jour avec succès !");
      setLocalMessage("Utilisateur mis à jour avec succès !");
      updateUserList();  // Met à jour la liste en temps réel dans UserView
      setIsLocked(true); // Relock le formulaire après sauvegarde
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'utilisateur :", error);
      setLocalMessage("Erreur lors de la mise à jour de l'utilisateur.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Modifier un Utilisateur</h6>
          <button className="btn btn-secondary" onClick={toggleLock}>
            <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />
          </button>
        </div>
        <div className="card-body">
          {localMessage && <div className="alert alert-success mb-3">{localMessage}</div>}
          <form onSubmit={handleSave}>
            <div className="form-row d-flex justify-content-between mb-3">
              <div className="form-group col-md-6">
                <label htmlFor="nom">Nom</label>
                <input
                  type="text"
                  className="form-control"
                  id="nom"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  required
                  disabled={isLocked}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="prenom">Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  id="prenom"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                  disabled={isLocked}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLocked}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password">Mot de passe</label>
              <button type="button" className="btn btn-warning w-100" disabled={isLocked}>
                Réinitialiser le mot de passe
              </button>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="role">Rôle</label>
              <select
                className="form-control"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
                disabled={isLocked}
              >
                <option value="">Sélectionner un rôle</option>
                {roles.map((roleOption, index) => (
                  <option key={`${roleOption}-${index}`} value={roleOption}>
                    {roleOption}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="secteur">Secteur</label>
              <select
                className="form-control"
                id="secteur"
                value={secteur}
                onChange={(e) => setSecteur(e.target.value)}
                disabled={isLocked}
              >
                <option value="">Sélectionner un secteur</option>
                {secteurs.map((secteurOption) => (
                  <option key={secteurOption.id} value={secteurOption.id}>
                    {secteurOption.nom}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="entreprise">Entreprise</label>
              <select
                className="form-control"
                id="entreprise"
                value={entreprise}
                onChange={(e) => setEntreprise(e.target.value)}
                disabled={isLocked}
              >
                <option value="">Sélectionner une entreprise</option>
                {filteredEntreprises.map((entrepriseOption) => (
                  <option key={entrepriseOption.id} value={entrepriseOption.id}>
                    {entrepriseOption.name}
                  </option>
                ))}
              </select>
            </div>

            {(role === 'vendor' || role === 'manager') && (
              <div className="form-group mb-3">
                <label htmlFor="site">Site</label>
                <select
                  className="form-control"
                  id="site"
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                  disabled={isLocked}
                >
                  <option value="">Sélectionner un site</option>
                  {sites.map((siteOption) => (
                    <option key={siteOption.id} value={siteOption.id}>
                      {siteOption.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {role === 'vendor' && (
              <div className="form-group mb-3">
                <label htmlFor="manager">Manager</label>
                <select
                  className="form-control"
                  id="manager"
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                  disabled={isLocked}
                >
                  <option value="">Sélectionner un manager</option>
                  {managers.map((managerOption) => (
                    <option key={managerOption.id} value={managerOption.id}>
                      {managerOption.first_name} {managerOption.last_name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <button type="submit" className="btn btn-primary mt-3" disabled={isLocked}>
              <FontAwesomeIcon icon={faSave} /> Sauvegarder
            </button>
            <button
              type="button"
              className="btn btn-danger mt-3 ml-3"
              onClick={() => setSelectedUser(null)}
              disabled={isLocked}
            >
              <FontAwesomeIcon icon={faTrash} /> Supprimer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
