// src/components/SuperAdmin/Gestion/Assureur/AssureurView.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import AssureurForm from './AssureurForm';
import AssureurSelect from './AssureurSelect';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssureurView = () => {
  const [assureurs, setAssureurs] = useState([]);
  const [filteredAssureurs, setFilteredAssureurs] = useState([]);
  const [selectedAssureur, setSelectedAssureur] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedSecteur, setSelectedSecteur] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchAssureurs = async () => {
      try {
        // Utilisez le chemin correct avec `/get-assureurs`
        const response = await axiosInstance.get('/assureurs/get-assureurs');
        // const response = await axios.get('http://localhost:5000/api/assureurs/get-assureurs');
        setAssureurs(response.data.assureurs || []);
        setFilteredAssureurs(response.data.assureurs || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des assureurs :", error);
        setMessage("Erreur lors de la récupération des assureurs.");
      }
    };
    fetchAssureurs();
  }, []);

  const updateAssureurList = async () => {
    try {
      const response = await axiosInstance.get('/assureurs/get-assureurs');
      // const response = await axios.get('http://localhost:5000/api/assureurs/get-assureurs');
      setAssureurs(response.data.assureurs || []);
      setFilteredAssureurs(response.data.assureurs || []);
      setShowForm(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des assureurs :", error);
    }
  };

  useEffect(() => {
    let filtered = assureurs;

    if (selectedSecteur) {
      filtered = filtered.filter(assureur => assureur.secteur_id === parseInt(selectedSecteur));
    }
    if (selectedStatus) {
      filtered = filtered.filter(assureur => assureur.status_id === parseInt(selectedStatus));
    }
    if (searchTerm) {
      filtered = filtered.filter(assureur =>
        assureur.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        assureur.siret.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredAssureurs(filtered);
  }, [selectedSecteur, selectedStatus, searchTerm, assureurs]);

  const handleAssureurClick = (assureur) => {
    setSelectedAssureur(assureur);
    setShowForm(true);
  };

  return (
    <div className="container-fluid px-2">
      <AssureurSelect
        selectedSecteur={selectedSecteur}
        selectedStatus={selectedStatus}
        setSelectedSecteur={setSelectedSecteur}
        setSelectedStatus={setSelectedStatus}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <div className="row">
        <div className="col-md-4">
          <div className="card shadow mb-4" style={{ maxHeight: '450px', overflowY: 'auto' }}>
            <ul className="list-group list-group-flush">
              {filteredAssureurs.length > 0 ? (
                filteredAssureurs.map(assureur => (
                  <li
                    key={assureur.id}
                    className={`list-group-item d-flex justify-content-between align-items-center ${assureur.id === selectedAssureur?.id ? 'active' : ''}`}
                    onClick={() => handleAssureurClick(assureur)}
                  >
                    <div>
                      <strong>{assureur.nom}</strong>
                      <p className="mb-0"><small>SIRET : {assureur.siret}</small></p>
                    </div>
                    <FontAwesomeIcon icon={faEye} />
                  </li>
                ))
              ) : (
                <li className="list-group-item">Aucun assureur trouvé</li>
              )}
            </ul>
          </div>
        </div>

        <div className="col-md-8">
          {showForm ? (
            <AssureurForm
              selectedAssureur={selectedAssureur}
              setSelectedAssureur={setSelectedAssureur}
              updateAssureurList={updateAssureurList}
              setMessage={setMessage}
            />
          ) : (
            <div className="alert alert-info">Sélectionnez un assureur pour voir les détails ou modifier.</div>
          )}
          {message && <div className="alert alert-info mt-3">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default AssureurView;
