import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import francais from '../../../assets/images/drapeaux/francais.png';
import anglais from '../../../assets/images/drapeaux/anglais.png';
import polonais from '../../../assets/images/drapeaux/polonais.png';
import logo from '../../../assets/images/vendor/logo.png';

const SuperAdminNavbar = ({ showBackButton = false, stepBack }) => {
  const { i18n, t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [adminName, setAdminName] = useState('Invité');
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    // Récupération des informations depuis le localStorage
    const firstName = localStorage.getItem('superadminFirstName');
    const lastName = localStorage.getItem('superadminLastName');
  
    if (lastName && firstName) {
      const formattedName = `${lastName}.${firstName.charAt(0)}`;
      setAdminName(formattedName);
    } else {
      setAdminName('Invité');
    }
  }, []);

  const flags = {
    fr: { src: francais, alt: 'Français' },
    en: { src: anglais, alt: 'English' },
    pl: { src: polonais, alt: 'Polski' }
  };

  const changeLanguage = (lng) => {
    setSelectedLanguage(lng);
    setDropdownOpen(false);
  };

  const handleSettings = () => navigate('/settings');

  const handleLogout = () => {
    localStorage.clear();
    setAdminName('Invité');
    navigate('/superadmin-login');
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light"
      style={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1040,
        height: '60px',
        paddingLeft: '250px',
      }}
    >
      <img 
        src={logo} 
        alt="Logo" 
        className="logo" 
        onClick={() => navigate('/superadmin-panel')} 
        style={{ cursor: 'pointer' }}
      />
      {showBackButton && (
        <button className="back-button" onClick={stepBack}>
          <i className="fas fa-arrow-left"></i> {t('Button.return')}
        </button>
      )}
      <div className="navbar-content">
        <div className="language-selector" onClick={() => setDropdownOpen(!dropdownOpen)}>
          <button className="language-button">
            <img src={flags[selectedLanguage].src} alt={flags[selectedLanguage].alt} className="flag-icon" />
          </button>
          {dropdownOpen && (
            <div className="dropdown-menu" onMouseLeave={() => setDropdownOpen(false)}>
              {Object.keys(flags).map((lng) => (
                <button key={lng} className="dropdown-item" onClick={() => changeLanguage(lng)}>
                  <img src={flags[lng].src} alt={flags[lng].alt} className="dropdown-flag-icon" />
                  {flags[lng].alt}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="user-info" onClick={() => setUserDropdownOpen(!userDropdownOpen)}>
          <span className="user-name" style={{ cursor: 'pointer' }}>{adminName}</span>
          <img className="user-profile" src="https://via.placeholder.com/40" alt="User profile" />
          {userDropdownOpen && (
            <div className="user-dropdown">
              <button onClick={handleSettings} className="user-dropdown-item">
                {t('Button.settings')}
              </button>
              <button onClick={handleLogout} className="user-dropdown-item">
                {t('Button.logout')}
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default SuperAdminNavbar;
