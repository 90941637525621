import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../API/axiosInstance'; 

const SuperAdminLogin = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axiosInstance.post('/superadmin-login', { email, password });
      // const response = await axios.post('/api/superadmin-login', { email, password });
      const { token, superadminId, first_name, last_name } = response.data;
  
      if (token && superadminId) {
        localStorage.setItem('superadminToken', token);
        localStorage.setItem('superadminId', superadminId);
  
        // Enregistrer first_name et last_name dans le localStorage
        localStorage.setItem('superadminFirstName', first_name);
        localStorage.setItem('superadminLastName', last_name);
  
        setIsAuthenticated(true);
        navigate('/superadmin-panel');
      } else {
        setError("Identifiants incorrects.");
      }
    } catch (err) {
      setError("Erreur de connexion.");
    }
  };
  
  

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="card p-4 shadow">
        <h2 className="text-center mb-4">Connexion SuperAdmin</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Mot de passe</label>
            <input
              type="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">Se connecter</button>
        </form>
      </div>
    </div>
  );
};

export default SuperAdminLogin;
