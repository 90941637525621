import React, { useEffect } from 'react';
import styles from './ParcoursClient.module.css';
import { useTranslation } from 'react-i18next';

const ProprieteVelo = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const handleSelection = (propriete) => {
    setFormData((prevData) => ({
      ...prevData,
      proprieteVelo: propriete,
    }));
    setCurrentStep(5); // Passe à l'étape suivante
  };

  // Utilisez useEffect pour forcer le défilement en haut de la page au chargement
  useEffect(() => {
    window.scrollTo(0, 0); // Force le défilement en haut de la page
  }, []);

  return (
    <div className="container my-4">
      <h2 className={`text-center mb-4 ${styles.titleHeading}`}>
        {t('ParcourClient.questions4.purchase_type')}
      </h2>
      
      <div className="d-flex flex-column align-items-center">
        <div
          className={`card text-center p-3 mb-3 ${styles.hoverCard}`}
          onClick={() => handleSelection('Propre')}
        >
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{t('ParcourClient.option_purchase_type.mybike')}</h5>
          </div>
        </div>

        <div
          className={`card text-center p-3 mb-3 ${styles.hoverCard}`}
          onClick={() => handleSelection('Leasing')}
        >
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{t('ParcourClient.option_purchase_type.leasingbike')}</h5>
          </div>
        </div>

        <div
          className={`card text-center p-3 mb-3 ${styles.hoverCard}`}
          onClick={() => handleSelection('Location longue durée')}
        >
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{t('ParcourClient.option_purchase_type.rentalbike')}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProprieteVelo;
