import React, { useEffect, useState } from 'react';
import styles from './ParcoursClient.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../API/axiosInstance'; 

const QuestionIcon = () => <i className="bi bi-question-circle" />;

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
    <path d="M13.485 1.757a.75.75 0 0 1 1.06 1.06l-8.485 8.485a.75.75 0 0 1-1.06 0L1.56 7.485a.75.75 0 0 1 1.06-1.06L6 9.795l7.485-7.485z"/>
  </svg>
);

const CrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
  </svg>
);

const SelectionAssurance = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const [assurances, setAssurances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssurances = async () => {
      try {
        const vendorId = localStorage.getItem('vendorId');
        if (!vendorId) {
          throw new Error('Vendor ID manquant');
        }

        const response = await axiosInstance.get(`/sites/${vendorId}/assurances`, {
        // const response = await axios.get(`/api/sites/${vendorId}/assurances`, {
          params: { vendorId },
        });

        // Utiliser un Set pour supprimer les doublons en fonction de l'id de l'assurance
        const uniqueAssurances = Array.from(new Set(response.data.map(a => a.id)))
          .map(id => response.data.find(a => a.id === id));

        setAssurances(uniqueAssurances);
      } catch (error) {
        console.error('Erreur lors de la récupération des assurances :', error.message || error);
        setError('Erreur lors de la récupération des assurances.');
      } finally {
        setLoading(false);
      }
    };

    fetchAssurances();
  }, []); // Le tableau de dépendances vide pour s'assurer que cela s'exécute une fois

  const handleSouscrire = (assurance) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedAssurance: assurance,
    }));
    setCurrentStep(8);
  };

  const handlePdfClick = (base64Data) => {
    if (!base64Data) {
      alert("Le document PDF est manquant.");
      return;
    }

    const blob = new Blob([Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))], {
      type: 'application/pdf',
    });
    return URL.createObjectURL(blob);
  };

  const guarantees = [
    { label: t('ParcourClient.assurance_content.steal'), key: "vol" },
    { label: t('ParcourClient.assurance_content.attemptsteal'), key: "tentative_vol" },
    { label: t('ParcourClient.assurance_content.break'), key: "dommages_materiels" },
    { label: t('ParcourClient.assurance_content.naturaldisasters'), key: "catastrophes_naturelles" },
    { label: t('ParcourClient.assurance_content.technologikdisasters'), key: "catastrophes_technologiques" }
  ];

  const totalAssurances = assurances.length;

  return (
    <div>
      <div className={styles.contentContainer}>
        <h1 className={styles.assurancesTitle}>
          {t('ParcourClient.quesion7.selection_assurance')}
          <span className={styles.assuranceCount}>{totalAssurances}</span>
        </h1>
        {loading ? (
          <div className="text-center">Chargement...</div>
        ) : error ? (
          <div className="text-center text-danger">{error}</div>
        ) : (
          <div className="row">
            {assurances.map((assurance) => (
              <div className="col-12 col-md-6 mb-4" key={`assurance-${assurance.id}`}>
                <div className={`card ${styles.assuranceCard}`}>
                  <div className={`card-header ${styles.cardHeader}`}>
                    <h5>{assurance.nom.toUpperCase()}</h5>
                  </div>
                  <div className={`card-body ${styles.assuranceContent}`}>
                    <div className={styles.assuranceDetails}>
                      <h6 className={styles.assurancetxt}>
                        <strong>{t('ParcourClient.assurance_content.value')}</strong>
                        <span className={styles.assuranceValue}>{assurance.prix} € / {t("ParcourClient.option_date_purchase.month")}</span>
                      </h6>
                      <p className="text-muted"><strong>{t('ParcourClient.assurance_content.description')}</strong> {assurance.description}</p>
                    </div>

                    <table className={styles.guaranteesTable}>
                      <thead>
                        <tr>
                          <th>{t('ParcourClient.assurance_content.guarantees')}</th>
                          <th>{t('ParcourClient.assurance_content.status')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guarantees.map((guarantee) => {
                          const franchise = assurance[`${guarantee.key}_franchise`];
                          const minFranchise = assurance[`min_fr_${guarantee.key}`];
                          return (
                            <tr key={`${assurance.id}-${guarantee.key}`}>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {franchise !== undefined && <div>{t('ParcourClient.assurance_content.franchise')} : {franchise}%</div>}
                                      {minFranchise !== undefined && <div>{t('ParcourClient.assurance_content.minimum')} : {minFranchise} €</div>}
                                    </Tooltip>
                                  }
                                >
                                  <span className={styles.infoIcon}><QuestionIcon /></span>
                                </OverlayTrigger>
                                <span className={styles.guaranteeLabel}>{guarantee.label}</span>
                              </td>
                              <td className="text-center">
                                {assurance[guarantee.key] ? (
                                  <CheckIcon className={styles.checkIcon} />
                                ) : (
                                  <CrossIcon className={styles.crossIcon} />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="mt-3">
                      {assurance.pdf_ipid && (
                        <a href={handlePdfClick(assurance.pdf_ipid)} target="_blank" rel="noopener noreferrer" className={styles.pdfLink}>
                          📄 {t('ParcourClient.assurance_content.ipid')}
                        </a>
                      )}
                      {assurance.pdf_notice && (
                        <a href={handlePdfClick(assurance.pdf_notice)} target="_blank" rel="noopener noreferrer" className={styles.pdfLink}>
                          📑 {t('ParcourClient.assurance_content.notice')}
                        </a>
                      )}
                    </div>
                    <button className={styles.nextStepBtn} onClick={() => handleSouscrire(assurance)}>
                      {t('Button.productsub')}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionAssurance;
