// src/components/SuperAdmin/Gestion/Assurance/AssuranceSelect.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssuranceSelect = ({
  selectedCategory,
  setSelectedCategory,
  selectedAssureur,
  setSelectedAssureur,
  selectedStatus,
  setSelectedStatus,
  selectedSecteur,
  setSelectedSecteur,
  searchTerm,
  setSearchTerm
}) => {
  const [categories, setCategories] = useState([]);
  const [assureurs, setAssureurs] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secteurs, setSecteurs] = useState([]);

  // Récupération des filtres au chargement
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const [categoriesRes, assureursRes, statusesRes, secteursRes] = await Promise.all([
          axiosInstance.get('/categories/get-categories'),
          axiosInstance.get('/assureurs/get-assureurs'),
          axiosInstance.get('/status/get-statuses'),
          axiosInstance.get('/secteurs/get-secteurs'),
          // axios.get('http://localhost:5000/api/categories/get-categories'),
          // axios.get('http://localhost:5000/api/assureurs/get-assureurs'),
          // axios.get('http://localhost:5000/api/status/get-statuses'),
          // axios.get('http://localhost:5000/api/secteurs/get-secteurs'),
        ]);
        setCategories(categoriesRes.data.categories);
        setAssureurs(assureursRes.data.assureurs);
        setStatuses(statusesRes.data.statuses);
        setSecteurs(secteursRes.data.secteurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des filtres :", error);
      }
    };
    fetchFilters();
  }, []);

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Filtrer les Assurances</h6>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-3">
            <label>Catégorie</label>
            <select
              className="form-control"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Toutes les catégories</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>{cat.nom}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>Assureur</label>
            <select
              className="form-control"
              value={selectedAssureur}
              onChange={(e) => setSelectedAssureur(e.target.value)}
            >
              <option value="">Tous les assureurs</option>
              {assureurs.map((assur) => (
                <option key={assur.id} value={assur.id}>{assur.nom}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>Statut</label>
            <select
              className="form-control"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Tous les statuts</option>
              {statuses.map((status) => (
                <option key={status.id} value={status.id}>{status.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>Secteur</label>
            <select
              className="form-control"
              value={selectedSecteur}
              onChange={(e) => setSelectedSecteur(e.target.value)}
            >
              <option value="">Tous les secteurs</option>
              {secteurs.map((secteur) => (
                <option key={secteur.id} value={secteur.id}>{secteur.nom}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher par nom"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssuranceSelect;
