import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import VendorNavbar from '../VendorNavbar';
import styles from './HistoriqueTransactions.module.css';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../API/axiosInstance'; 

const HistoriqueTransactions = () => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const vendorId = localStorage.getItem('vendorId');
        const response = await axiosInstance.get('/transactions', {
        // const response = await axios.get('http://localhost:5000/api/transactions', {
          params: { vendorId },
        });
        setTransactions(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des transactions', error);
        setError('Erreur lors de la récupération des transactions.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const handleBack = () => {
    navigate('/vendor-panel');
  };

  const columns = [
    {
      title: t('TransactionPage.transaction'),
      dataIndex: 'transactionId',
      key: 'transactionId',
      sorter: (a, b) => a.transactionId.localeCompare(b.transactionId),
    },
    {
      title: t('TransactionPage.status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Success', value: 'success' },
        { text: 'Pending', value: 'pending' },
        { text: 'Failed', value: 'failed' },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag color={status === 'success' ? 'green' : status === 'pending' ? 'orange' : 'red'}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t('TransactionPage.creationdate'),
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      sorter: (a, b) => new Date(a.transaction_date) - new Date(b.transaction_date),
      render: (date) => (date ? new Date(date).toLocaleString() : 'N/A'),
    },
    {
      title: t('TransactionPage.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('TransactionPage.namebuyer'),
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: t('TransactionPage.lastnamebuyer'),
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: t('TransactionPage.emailbuyer'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('TransactionPage.price'),
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (amount) => `${amount} €`,
    },
    {
      title: t('TransactionPage.method'),
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: t('TransactionPage.realisation'),
      dataIndex: 'realizationDate',
      key: 'realizationDate',
    },
  ];

  const totalTransactions = transactions.length;

  return (
    <div>
      <VendorNavbar hideSearchBar={true} showBackButton={true} stepBack={handleBack} />
      <div className={styles.historiqueContainer} ref={contentRef}>
        <h1 className={styles.historiqueTitle}>
          {t('TransactionPage.title')}
          <span className={styles.transactionCount}>{totalTransactions}</span>
        </h1>

        {loading ? (
          <div className="text-center">Chargement des transactions...</div>
        ) : error ? (
          <div className="text-danger text-center">{error}</div>
        ) : (
          <div className={styles.tableContainer}>
            <Table
              columns={columns}
              dataSource={transactions}
              rowKey="transactionId"
              pagination={{ pageSize: 10 }}
              scroll={{ x: '100%' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoriqueTransactions;
