import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './LoadingPayment.module.css';
import { useTranslation } from 'react-i18next';
import chargementImage from '../../../assets/images/vendor/chargement.png';
import axiosInstance from '../../../API/axiosInstance'; 

const LoadingPayment = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { transactionId } = location.state || {}; 
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkTransactionStatus = async () => {
      console.log("Vérification du statut de la transaction avec le title :", transactionId);
    
      try {
        const response = await axiosInstance.get(`/transaction-status/${transactionId}`, {
          headers: {
            'Cache-Control': 'no-cache' // Désactive la mise en cache
          }
        });
        
        const currentStatus = response.data.status;
        if (currentStatus === 'success' || currentStatus === 'failed') {
          setStatus(currentStatus);
          setLoading(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          console.warn("Transaction non trouvée pour le transactionId :", transactionId);
          setError('Transaction non trouvée. Veuillez vérifier le numéro de transaction.');
        } else {
          console.error('Erreur lors de la vérification du statut de la transaction:', err.message);
          setError('Erreur lors de la récupération du statut de la transaction.');
        }
        setLoading(false);
      }
    };

    const interval = setInterval(checkTransactionStatus, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [transactionId]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('LoadPage.Load.Loadchargment')}</p>
      </div>
    );
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <div className={styles.paymentResultContainer}>
      <div className={styles.statusMessage}>
        <h2>{t('LoadPage.statustransac.status')}</h2>
        {status === 'success' ? (
          <div>
            <p className="text-success">{t('LoadPage.Sucess.status')}</p>
            <p>{t('LoadPage.statustransac.message')}</p>
          </div>
        ) : (
          <p className="text-danger">{t('LoadPage.Error.status')}</p>
        )}
        <button className={styles.completeTransactionButton} onClick={() => navigate('/panel-vendor')}>
          {t('Button.LoadPage.finishtransac')}
        </button>
      </div>
      <div className={styles.imageContainer}>
        <img src={chargementImage} alt="Chargement" className={styles.loadingImage} />
      </div>
    </div>
  );
};

export default LoadingPayment;
