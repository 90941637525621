// src/components/SuperAdmin/Gestion/User/UserView.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import UserSelect from './UserSelect';
import UserForm from './UserForm';
import axiosInstance from '../../../../API/axiosInstance'; 

const UserView = () => {
  const [users, setUsers] = useState([]); // Liste complète des utilisateurs
  const [filteredUsers, setFilteredUsers] = useState([]); // Liste filtrée des utilisateurs
  const [selectedUser, setSelectedUser] = useState(null); // Utilisateur actuellement sélectionné pour affichage/modification
  const [showForm, setShowForm] = useState(false); // État pour afficher/masquer UserForm
  const [selectedSecteur, setSelectedSecteur] = useState('');
  const [selectedEntreprise, setSelectedEntreprise] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');

  // Récupère les utilisateurs au chargement du composant
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/users/get-users');
        // const response = await axios.get('http://localhost:5000/api/users/get-users');
        const uniqueUsers = Array.from(new Map(response.data.users.map(user => [user.id, user])).values());
        setUsers(uniqueUsers);
        setFilteredUsers(uniqueUsers);
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs:", error);
        setMessage("Erreur lors de la récupération des utilisateurs.");
      }
    };
    fetchUsers();
  }, []);

  // Fonction mise à jour pour l'utilisateur modifié
  const updateUserList = (updatedUser) => {
    if (updatedUser && updatedUser.id) { // Vérifie que updatedUser et son id sont définis
      setUsers(prevUsers => 
        prevUsers.map(user => user && user.id === updatedUser.id ? updatedUser : user)
      );
    }
  };

  // Filtrage dynamique basé sur les sélections et le terme de recherche
  useEffect(() => {
    let filtered = users.filter(user => user); // Supprime les valeurs `undefined` ou `null`

    if (selectedSecteur) {
      filtered = filtered.filter(user => user.secteur_id === parseInt(selectedSecteur));
    }
    if (selectedEntreprise) {
      filtered = filtered.filter(user => user.entreprise_id === parseInt(selectedEntreprise));
    }
    if (selectedRole) {
      filtered = filtered.filter(user => user.role === selectedRole);
    }
    if (searchTerm) {
      filtered = filtered.filter(user =>
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredUsers(filtered);
  }, [selectedSecteur, selectedEntreprise, selectedRole, searchTerm, users]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setSelectedUser(null);
    setShowForm(false);
  };

  return (
    <div className="container-fluid px-2">
      <UserSelect
        selectedSecteur={selectedSecteur}
        selectedEntreprise={selectedEntreprise}
        selectedRole={selectedRole}
        setSelectedSecteur={setSelectedSecteur}
        setSelectedEntreprise={setSelectedEntreprise}
        setSelectedRole={setSelectedRole}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <div className="row">
        <div className="col-md-4">
          <div className="card shadow mb-4" style={{ maxHeight: '450px', overflowY: 'auto' }}>
            <ul className="list-group list-group-flush">
              {filteredUsers.length > 0 ? (
                filteredUsers.map(user => (
                  user && (
                    <li
                      key={user.id}
                      className={`list-group-item d-flex justify-content-between align-items-center ${user.id === selectedUser?.id ? 'active' : ''}`}
                      onClick={() => handleUserClick(user)}
                    >
                      <div>
                        <strong>{user.first_name} {user.last_name}</strong>
                        <p className="mb-0"><small>{user.role}</small></p>
                      </div>
                      <FontAwesomeIcon icon={faEye} />
                    </li>
                  )
                ))
              ) : (
                <li className="list-group-item">Aucun utilisateur trouvé</li>
              )}
            </ul>
          </div>
        </div>
        
        <div className="col-md-8">
          {showForm ? (
            <UserForm 
              selectedUser={selectedUser} 
              setSelectedUser={setSelectedUser}
              updateUserList={updateUserList} 
              setMessage={setMessage}
              onClose={handleCloseForm}
            />
          ) : (
            <div className="alert alert-info">Sélectionnez un utilisateur pour voir les détails ou modifier.</div>
          )}
          {message && <div className="alert alert-info mt-3">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default UserView;
